import axios from "axios";
import defaultUser from "../utils/default-user";
import { alert } from "devextreme/ui/dialog";
import { Cookies } from "react-cookie";
import * as U from "../utils";

//const preDomain = "http://localhost:8080"; // for different origins (개발환경): npm start
const preDomain = ""; // for same origin (Release): npm run build

const cookies = new Cookies();

// Login
export async function login(_affiliation: string, _id: string, password: string) {
  try {
    // Send request
    const uploadData = {
      affiliation: _affiliation,
      id: _id,
      password: password,
    };

    let _response_status = -1;
    let _level = "";
    let _acc_admin = 0;
    let _acc_tx = 0;
    let _acc_proj = 0;
    let _acc_facs = 0;
    let _avatarUrl = "";
    let _cartridgeCodeList: Array<string> = new Array<string>();
    let _cartridgeOutflowList: Array<number> = new Array<number>();

    await axios({
      url: `${preDomain}/login`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      const response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      // userId, userAffiliation에 대해 쿠키로 저장해두고 이후의 서버에 대한 요청엔 모두 자동으로 쿠키가 header에 추가된다.
      const { affiliation, id, level, acc_admin, acc_tx, acc_proj, acc_facs } = response.data;
      cookies.set("userId", id);
      cookies.set("userAffiliation", affiliation);

      _affiliation = affiliation;
      _id = id;
      _level = level;
      _acc_admin = acc_admin;
      _acc_tx = acc_tx;
      _acc_proj = acc_proj;
      _acc_facs = acc_facs;
    });

    // downlaod avatar image
    if (_response_status == 0) {
      await axios({
        url: `${preDomain}/login/downloadavatarimage`,
        method: "POST",
        headers: {
          ContentType: "application/json",
        },
        responseType: "blob",
        withCredentials: true,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
        _avatarUrl = url;
      });

      // download cartridge list
      if (_response_status == 0) {
        let query: string = "SELECT code, outflow FROM cartridge";
        const uploadData2 = {
          query: query,
        };
        await axios({
          url: `${preDomain}/project/querycartridge`,
          method: "POST",
          data: uploadData2,
          headers: {
            ContentType: "application/json",
          },
          withCredentials: true,
        }).then((response) => {
          const response_status: string = cookies.get("response_status");
          let nResponse_status = parseInt(response_status);
          if (nResponse_status === 0) {
            let jsonData: Array<Object> = new Array<Object>();
            jsonData = response.data;
            _cartridgeCodeList = U.stringArrayFromJsonArray(jsonData, "code");
            _cartridgeOutflowList = U.floatArrayFromJsonArray(jsonData, "outflow");
          }
        });
      }

      return {
        isOk: true,
        data: {
          avatarUrl: _avatarUrl,
          affiliation: _affiliation,
          id: _id,
          level: _level,
          acc_admin: _acc_admin,
          acc_tx: _acc_tx,
          acc_proj: _acc_proj,
          acc_facs: _acc_facs,
        },
        cartridgeCodeList: _cartridgeCodeList,
        cartridgeOutflowList: _cartridgeOutflowList,
      };
    } else if (_response_status == 1) {
      // 1: Login id does not exist
      return {
        isOk: false,
        data: defaultUser,
        message: "ID does not exist!",
        cartridgeCodeList: _cartridgeCodeList,
        cartridgeOutflowList: _cartridgeOutflowList,
      };
    } else if (_response_status == 2) {
      // 2: Login password does not match
      return {
        isOk: false,
        data: defaultUser,
        message: "Password does not match!",
        cartridgeCodeList: _cartridgeCodeList,
        cartridgeOutflowList: _cartridgeOutflowList,
      };
    } else {
      return {
        isOk: false,
        data: defaultUser,
        message: "Failed to connnect to server!",
        cartridgeCodeList: _cartridgeCodeList,
        cartridgeOutflowList: _cartridgeOutflowList,
      };
    }
  } catch {
    return {
      isOk: false,
      data: defaultUser,
      message: "Failed to connnect to server!",
      cartridgeCodeList: new Array<string>(),
      cartridgeOutflowList: new Array<number>(),
    };
  }
}

export async function changePasswordAndAlert(password: string, newPassword: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      password: password,
      newPassword: newPassword,
    };
    await axios({
      url: `${preDomain}/login/changepassword`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      const response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
    });

    if (_response_status == 0) {
      alert("Password changed!", "Success");
      return;
    } else if (_response_status == 2) {
      // 2: Login password does not match
      alert("Password does not match!", "Error");
      return;
    } else {
      alert("Failed to change password!", "Error");
      return;
    }
  } catch {
    alert("Failed to change password!", "Error");
    return;
  }
}

// General Purpose
export async function queryJsonArray(path: string, query: string) {
  // 서버회신을 await 형태로 다 동기처리했고 return  jsonData 형태이므로 queryJson을 호출한 코드에서 .then으로 return한 값을 사용한 추가작업을 이어갈 수 있다.
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      query: query,
    };

    let jsonData = new Array<Object>();
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("Login again!", "Timeout").then(() => {
          window.location.reload();
        });
      }
      jsonData = response.data;
    });

    return jsonData;
  } catch {
    return new Array<Object>();
  }
}

export async function readJson(path: string, id: string) {
  // 서버회신을 await 형태로 다 동기처리했고 return  jsonData 형태이므로 queryJson을 호출한 코드에서 .then으로 return한 값을 사용한 추가작업을 이어갈 수 있다.
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      id: id,
    };

    let jsonData: Object = new Object();
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("Login again!", "Timeout").then(() => {
          window.location.reload();
        });
      }
      if (_response_status === 0) {
        jsonData = response.data;
      } else {
        if (_response_status === 20) {
          alert("No item exists!", "Error").then(() => {});
        }
      }
    });
    return jsonData;
  } catch {
    return new Object();
  }
}

export async function uploadJsonAndAlert(path: string, json: Object) {
  // 1차원 json (Object), 2차원 json (Array<Object>) 모두 가능
  let _response_status: number = -1;
  try {
    // Send request
    let _response_status = -1;
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: json,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status === 0) {
      alert("Upload succeeded!", "Success");
    } else {
      alert("Failed to upload!", "Error");
    }
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function uploadJson(path: string, json: Object) {
  // 1차원 json (Object), 2차원 json (Array<Object>) 모두 가능
  // return response_status 이므로 이 함수를 호출한 곳에서 아래와 같이 .then으로 작업을 이어갈 수 있다. .then((response_status: number) => {  ... });
  try {
    // Send request
    let _response_status = -1;
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: json,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    return _response_status;
  } catch {
    return -1;
  }
}

export async function deleteRowByNo(path: string, no: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { no: no };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function deleteRowById(path: string, id: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { id: id };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function downloadAndOpenPdf(path: string, fileName: string) {
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      fileName: fileName,
    };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
      responseType: "blob",
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url);
    });
  } catch {}
}

// Device CoA
export async function searchDeviceCoa(deviceSerial: string) {
  try {
    let _response_status = -1;
    let _no = -1;

    // Send request
    const uploadData = {
      deviceSerial: deviceSerial,
    };

    await axios({
      url: `${preDomain}/coa/searchdevicecoa`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
        return [_response_status, _no];
      }
      if (_response_status === 0) {
        const { no } = response.data;
        _no = no;
      } else {
        return [_response_status, _no];
      }
    });

    return [_response_status, _no];
  } catch {
    return new Array<Object>();
  }
}

export async function deleteDeviceCoaByDeviceSerial(deviceSerial: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { deviceSerial: deviceSerial };
    await axios({
      url: `${preDomain}/coa/deletedevicecoa`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function uploadDeviceCoaWithFile(deviceSerial: string, file: File) {
  try {
    let _response_status = -1;
    let _no = -1;

    // Send request
    const uploadData = {
      deviceSerial: deviceSerial,
    };

    await axios({
      url: `${preDomain}/coa/uploaddevicecoawithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
          return [_response_status, _no];
        }
        if (_response_status === 0) {
          const { no } = response.data;
          _no = no;
        } else {
          return [_response_status, _no];
        }
      })
      .catch((err) => {});

    if (_response_status === 22) {
      return [_response_status, _no];
    }

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return [_response_status, _no];
    }
    const formData = new FormData();
    formData.append("file", file, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/coa/uploaddevicecoafile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
    return [_response_status, _no];
  } catch {
    alert("Failed to upload!", "Error");
    return [-1, -1];
  }
}

export async function deleteAndUploadDeviceCoaByDeviceSerial(deviceSerial: string, file: File) {
  try {
    // Send request
    const uploadData = {
      deviceSerial: deviceSerial,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/coa/deleteanduploaddevicecoawithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
          return [_response_status, _no];
        }
        if (_response_status === 0) {
          const { no } = response.data;
          _no = no;
        } else {
          return [_response_status, _no];
        }
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return [_response_status, _no];
    }
    const formData = new FormData();
    formData.append("file", file, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/coa/uploaddevicecoafile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
    return [_response_status, _no];
  } catch {
    alert("Failed to upload!", "Error");
    return [-1, -1];
  }
}

// Cartridge CoA
export async function searchCartridgeCoa(catNo: string, lotNo: string) {
  try {
    let _response_status = -1;
    let _no = -1;

    // Send request
    const uploadData = {
      catNo: catNo,
      lotNo: lotNo,
    };

    await axios({
      url: `${preDomain}/coa/searchcartridgecoa`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
        return [_response_status, _no];
      }
      if (_response_status === 0) {
        const { no } = response.data;
        _no = no;
      } else {
        return [_response_status, _no];
      }
    });

    return [_response_status, _no];
  } catch {
    return new Array<Object>();
  }
}

export async function deleteCartridgeCoaByCatNoAndLotNo(catNo: string, lotNo: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { catNo: catNo, lotNo: lotNo };
    await axios({
      url: `${preDomain}/coa/deletecartridgecoa`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function uploadCartridgeCoaWithFile(catNo: string, lotNo: string, file: File) {
  try {
    let _response_status = -1;
    let _no = -1;

    // Send request
    const uploadData = {
      catNo: catNo,
      lotNo: lotNo,
    };

    await axios({
      url: `${preDomain}/coa/uploadcartridgecoawithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
          return [_response_status, _no];
        }
        if (_response_status === 0) {
          const { no } = response.data;
          _no = no;
        } else {
          return [_response_status, _no];
        }
      })
      .catch((err) => {});

    if (_response_status === 22) {
      return [_response_status, _no];
    }

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return [_response_status, _no];
    }
    const formData = new FormData();
    //formData.append("file", file, `${_no}.pdf`);
    formData.append("file", file, `${catNo}${lotNo}.pdf`);

    await axios({
      url: `${preDomain}/coa/uploadcartridgecoafile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
    return [_response_status, _no];
  } catch {
    alert("Failed to upload!", "Error");
    return [-1, -1];
  }
}

export async function deleteAndUploadCartridgeCoaByCatNoAndLotNo(catNo: string, lotNo: string, file: File) {
  try {
    // Send request
    const uploadData = {
      catNo: catNo,
      lotNo: lotNo,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/coa/deleteanduploadcartridgecoawithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
          return [_response_status, _no];
        }
        if (_response_status === 0) {
          const { no } = response.data;
          _no = no;
        } else {
          return [_response_status, _no];
        }
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return [_response_status, _no];
    }
    const formData = new FormData();
    //formData.append("file", file, `${_no}.pdf`);
    formData.append("file", file, `${catNo}${lotNo}.pdf`);

    await axios({
      url: `${preDomain}/coa/uploadcartridgecoafile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
    return [_response_status, _no];
  } catch {
    alert("Failed to upload!", "Error");
    return [-1, -1];
  }
}

// C1Facs
export async function downloadFacsImages(facsId: string) {
  // 서버회신을 await 형태로 다 동기처리했고 return  jsonData 형태이므로 queryJson을 호출한 코드에서 .then으로 return한 값을 사용한 추가작업을 이어갈 수 있다.
  let _response_status: number = -1;
  ///////////////
  console.log("facsId:", facsId);
  try {
    let urlArray: Array<string> = new Array<string>();
    for (let i = 0; i < 10; i++) {
      let filename: string = `${facsId}C${i + 1}.png`;
      const uploadData = {
        filename: filename,
      };
      let _url: string = "";
      // Send request
      await axios({
        url: `${preDomain}/tx/downloadimage`,
        method: "POST",
        data: uploadData,
        headers: {
          ContentType: "application/json",
        },
        responseType: "blob",
        withCredentials: true,
      }).then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
        _url = url;
      });
      console.log(i, _response_status);
      if (_response_status === 0) urlArray.push(_url);
      else break;
    }

    console.log("urlArray:", urlArray);
    return urlArray;
  } catch {
    return new Array<string>();
  }
}

// etc
export async function test() {
  // Send request
  const uploadData = {};

  let _response_status = -1;
  await axios({
    url: `${preDomain}/login/test`,
    method: "POST",
    data: uploadData,
    headers: {
      ContentType: "application/json",
    },
    withCredentials: true,
  }).then((response) => {
    let response_status: string = cookies.get("response_status");
    _response_status = parseInt(response_status);
    if (_response_status === 3) {
      alert("다시 로그인 하세요!", "시간초과").then(() => {
        window.location.reload();
      });
    }
  });
  if (_response_status === 0) {
    alert("Test upload succeeded!", "Success");
  } else {
    alert("Test upload failed!", "Error");
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}

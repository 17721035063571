import "./project.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProject } from "../../contexts/project";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Project() {
  const { user } = useAuth();
  const projectContext = useProject();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_proj = 0;
    if (user) {
      setUserId(user.id);
      acc_proj = user.acc_proj;
    }
    // (proj) [0] query for project, cell, mat, protocol, [1] project W & E, [2] D
    if (U.bitAt(acc_proj, 1)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_proj, 2)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedId("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY status Asc, id Asc";
    queryJsonArray("/project/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let maxVolume1: number = U.intValueFromJson(oneRowJson, "maxVolume");
        let maxVolume2: string = "Off";
        if (maxVolume1 === 1) maxVolume2 = "On";
        oneRowJson = U.addStringToJson(oneRowJson, "maxVolume2", maxVolume2);
        if (oneRowJson === null) continue;
        // ...
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
      //setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    projectContext.id = "";
    navigate("/project/new");
  }

  function copyToProjectContext(obj: Object) {
    projectContext.id = U.stringValueFromJson(obj, "id");
    projectContext.status = U.stringValueFromJson(obj, "status");
    projectContext.manager = U.stringValueFromJson(obj, "manager");
    projectContext.protocol = U.stringValueFromJson(obj, "protocol");
    projectContext.cellName = U.stringValueFromJson(obj, "cellName");
    projectContext.cellType = U.stringValueFromJson(obj, "cellType");
    projectContext.cellSize = U.stringValueFromJson(obj, "cellSize");
    projectContext.cellBuffer = U.stringValueFromJson(obj, "cellBuffer");
    projectContext.cellConc = U.stringValueFromJson(obj, "cellConc");
    projectContext.matName = U.stringValueFromJson(obj, "matName");
    projectContext.matType = U.stringValueFromJson(obj, "matType");
    projectContext.matMolWeight = U.stringValueFromJson(obj, "matMolWeight");
    projectContext.matBuffer = U.stringValueFromJson(obj, "matBuffer");
    projectContext.matConc = U.stringValueFromJson(obj, "matConc");
    projectContext.cartridge = U.stringValueFromJson(obj, "cartridge");
    projectContext.outflow = U.stringValueFromJson(obj, "outflow");
    projectContext.mixingRatio = U.stringValueFromJson(obj, "mixingRatio");
    projectContext.freq = U.stringValueFromJson(obj, "freq");
    projectContext.voltage = U.stringValueFromJson(obj, "voltage");
    projectContext.duty = U.stringValueFromJson(obj, "duty");
    projectContext.cooler = U.stringValueFromJson(obj, "cooler");
    projectContext.maxVolume = U.stringValueFromJson(obj, "maxVolume");
    projectContext.harvestVolume = U.stringValueFromJson(obj, "harvestVolume");
    projectContext.startDate = U.dateValueFromJson(obj, "startDate");
    projectContext.numberOfUse = U.stringValueFromJson(obj, "numberOfUse");
    projectContext.used_at = U.dateValueFromJson(obj, "used_at");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToProjectContext(selectedRow);
    navigate("/project/new");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/project/deleteproject", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Project</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="Project Name" />
              <Column dataField="status" caption="Status" />
              <Column dataField="startDate" caption="Start Date" dataType="date" />
              <Column dataField="protocol" caption="Protocol" />
              <Column dataField="cartridge" caption="Cartridge" />
              <Column dataField="cellName" caption="Cell Name" />
              <Column dataField="cellType" caption="Cell Type" />
              <Column dataField="cellSize" caption="Cell Size (um)" dataType="number" format="#,##0.#" />
              <Column dataField="cellBuffer" caption="Cell Buffer" />
              <Column dataField="cellConc" caption="Cell Conc. (10^6 cells/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="matName" caption="Mat Name" />
              <Column dataField="matType" caption="Mat Type" />
              <Column dataField="matMolWeight" caption="Mat Mol. Weight (kDa)" dataType="number" format="#,##0.#" />
              <Column dataField="matBuffer" caption="Mat Buffer" />
              <Column dataField="matConc" caption="Mat Conc. (ug/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="outflow" caption="Outflow (mL/h)" dataType="number" format="#,##0.#" />
              <Column dataField="mixingRatio" caption="Mixing Ratio (Mat/Cell)" dataType="number" format="#,##0.#" />
              <Column dataField="freq" caption="Frequency (kHz)" dataType="number" format="#,##0.#" />
              <Column dataField="voltage" caption="Voltage (V)" dataType="number" format="#,##0.#" />
              <Column dataField="duty" caption="Duty (%)" dataType="number" format="#,##0.#" />
              <Column dataField="cooler" caption="Cooler (%)" dataType="number" format="#,##0.#" />
              <Column dataField="maxVolume2" caption="Max Volume" />
              <Column dataField="harvestVolume" caption="Harvest Volume (mL)" dataType="number" format="#,##0.#" />
              <Column dataField="manager" caption="Project Manager" />
              <Column dataField="numberOfUse" caption="Number of Use" />
              <Column dataField="used_at" caption="Used At" dataType="datetime" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

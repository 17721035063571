import * as U from ".";

export const stringValueFromJson = (json: Object, keyName: string) => {
  // {}로 묶인 하나의 json에 대해 (Array 아니어야 함) keyName을 가진 항목을 찾아서 string으로 반환한다.
  // {"id":"2022-1","year":2022,"title":"임원급여","turn":1,"accounts":"801","sum":null,"jan":null,"feb":null,"mar":null,"apr":null,"may":null,"jun":null,"jul":null,"aug":null,"sep":null,"oct":null,"nov":null,"dec":null,"set_by":"jkpark","set_at":"2023-02-11T06:32:54.000Z","updated_by":null,"updated_at":null}
  // new line issue: json에는 "\n"으로 들어가 있고 이를 stringify로 string을 생성하면 "\"와 "n"이 따로 들어가게 된다. 이를 "\n"로 바꾸도록 추가 작업이 필요하다.
  if (!json) return "";
  let str: string = JSON.stringify(json);
  if (str.length < 2) return "";
  str = str.substring(1, str.length - 1);
  let splitted = str.split(',"'); // value는 ""가 없을 수도 있다. 가령 null
  for (let i = 0; i < splitted.length; i++) {
    let str2 = splitted[i]; // "id":"20211018-01" or id":"20211018-01"
    let keyAndValue = str2.split(":");
    //str2 = str2.replaceAll('"', "");
    if (keyAndValue.length === 2) {
      let key: string = keyAndValue[0];
      let value: string = keyAndValue[1];
      key = key.replaceAll('"', "");
      if (value === "null") value = "";
      else value = value.replaceAll('"', "");
      value = value.replaceAll("\\n", "\n");
      if (key === keyName) return value;
    } else {
      // date의 경우 "date":"2023-02-19T14:56:06.000Z", 로내부에 ":" 이 이미 있다.
      let colonIndex: number = str2.indexOf(":");
      if (colonIndex < 0) continue;
      let key: string = str2.substring(0, colonIndex);
      let value: string = str2.substring(colonIndex + 1, str.length);
      key = key.replaceAll('"', "");
      value = value.replaceAll('"', "");
      value = value.replaceAll("\\n", "\n");
      if (key === keyName) return value;
    }
  }
  return "";
};

export const stringArrayFromJsonArray = (jsonArray: Array<Object>, keyName: string) => {
  // json Array에 대해 keyName을 가진 항목을 찾아서 Array<string>으로 반환한다.
  let list: Array<string> = new Array<string>();
  if (!jsonArray) return list;
  for (let i = 0; i < jsonArray.length; i++) {
    let str: string = stringValueFromJson(jsonArray[i], keyName);
    if (str.length > 0) list.push(str);
  }
  return list;
};

export const floatArrayFromJsonArray = (jsonArray: Array<Object>, keyName: string) => {
  // json Array에 대해 keyName을 가진 항목을 찾아서 Array<number>으로 반환한다.
  let list: Array<number> = new Array<number>();
  if (!jsonArray) return list;
  for (let i = 0; i < jsonArray.length; i++) {
    let numValue: number = floatValueFromJson(jsonArray[i], keyName);
    if (!Number.isNaN(numValue)) list.push(numValue);
  }
  return list;
};

export const booleanValueFromJson = (json: Object, keyName: string) => {
  let numValue: number = intValueFromJson(json, keyName);
  if (numValue === 0) return false;
  else return true;
};

export const intValueFromJson = (json: Object, keyName: string) => {
  let strValue: string = stringValueFromJson(json, keyName);
  let numValue: number = parseInt(strValue);
  if (Number.isNaN(numValue)) return 0;
  else return numValue;
};

export const floatValueFromJson = (json: Object, keyName: string) => {
  let strValue: string = stringValueFromJson(json, keyName);
  let numValue: number = parseFloat(strValue);
  if (Number.isNaN(numValue)) return 0.0;
  else return numValue;
};

export const dateValueFromJson = (json: Object, keyName: string) => {
  let strValue: string = stringValueFromJson(json, keyName);
  let dateValue: Date = new Date(strValue);
  return dateValue;
};

export const addStringToJson = (json: object | null, keyName: string, value: string) => {
  // {"date":20230210,"startPrice":12500,"endPrice":12900,"highPrice":12900,"lowPrice":12500,"weightedMeanPrice":12700,"tradeStock":2,"tradeTotalPrice":25400,"totalStock":8401272,"totalPrice":108376408800,"updated_at":"2023-02-10T16:00:06.000Z"}
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ","; // 이미 {} 안에 항목이 있다면 comma를 추가하고 시작한다.
  str2 += `"${keyName}":"${value}"`;
  str2 += "}";
  //console.log(str2);
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

export const addDateToJson = (json: object | null, keyName: string, value: Date) => {
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ","; // 이미 {} 안에 항목이 있다면 comma를 추가하고 시작한다.
  str2 += `"${keyName}":"${value}"`;
  str2 += "}";
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

export const addNumberToJson = (json: object | null, keyName: string, value: number) => {
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ","; // 이미 {} 안에 항목이 있다면 comma를 추가하고 시작한다.
  str2 += `"${keyName}":${value}`;
  str2 += "}";
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    let str3: string = JSON.stringify(obj);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

export const addStringWithCommaToJson = (
  // 주어진 number 타입의 값을 #,#0 형태로 출력해서 string으로 json에 저장
  json: object | null,
  keyName: string,
  value: number
) => {
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ","; // 이미 {} 안에 항목이 있다면 comma를 추가하고 시작한다.
  str2 += `"${keyName}":${value}`;
  str2 += "}";
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

export const addIntToJson = (json: object | null, keyName: string, value: number) => {
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ","; // 이미 {} 안에 항목이 있다면 comma를 추가하고 시작한다.
  str2 += `"${keyName}":${value}`;
  str2 += "}";
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

export const addDateFromEightDigitIntToJson = (json: object | null, keyName: string, eightDigitIntDate: number) => {
  // eigitDigitInt를 입력받아 Date 형식으로 변환해서 json에 추가한다. 0을 입력받으면 1899.11.30 날짜로 생성되므로 0을 입력하지 않도록 주의해야 한다.
  let dateYMD = U.intArrayFromEightDigitIntDate(eightDigitIntDate);
  let date: Date = new Date(dateYMD[0], dateYMD[1] - 1, dateYMD[2]);
  let str1: string = JSON.stringify(json);
  let str2 = str1.substring(0, str1.length - 1);
  if (str2[str2.length - 1] !== "{") str2 += ",";
  str2 += `"${keyName}":"${date}"`;
  str2 += "}";
  let obj: Object = new Object();
  let success: boolean = false;
  try {
    obj = JSON.parse(str2);
    success = true;
  } catch (err) {}
  if (success) return obj;
  else return null;
};

// Array 관련 tool
export const addStringArrayIfNotExist = (
  // 기존의 list 안에 해당 string이 없는 경우에만 list 안에 추가, 추가하면 return true
  list: Array<string>,
  item: string
) => {
  if (!list) return false;
  if (item.length == 0) return false;
  for (let i = 0; i < list.length; i++) {
    if (list[i] === item) return false;
  }
  list.push(item);
  return true;
};

export const getStringValueFromStringArray = (
  // keyList, valueList의 array 에서 keyList의 멤버가 입력된 key와 같은 경우 같은 index의 valueList의 item 반환한다. 없으면 ""을 반환한다.
  key: string,
  keyList: Array<string>,
  valueList: Array<string>
) => {
  if (!keyList) return "";
  if (!valueList) return "";
  if (key.length == 0) return "";
  for (let i = 0; i < keyList.length; i++) {
    if (keyList[i] === key) {
      if (valueList.length > i) return valueList[i];
      return "";
    }
  }
  return "";
};

export const getNumberValueFromStringArray = (
  // keyList, valueList의 array 에서 keyList의 멤버가 입력된 key와 같은 경우 같은 index의 valueList의 item 반환한다. 없으면 ""을 반환한다.
  key: string,
  keyList: Array<string>,
  valueList: Array<number>
) => {
  if (!keyList) return null;
  if (!valueList) return null;
  if (key.length == 0) return null;
  for (let i = 0; i < keyList.length; i++) {
    if (keyList[i] === key) {
      if (valueList.length > i) return valueList[i];
      return null;
    }
  }
  return null;
};

export const getStringValueFromNumberArray = (
  // keyList, stringList의 array 에서 keyList의 멤버가 입력된 key와 같은 경우 같은 index의 stringList의 item 반환한다. 없으면 ""을 반환한다.
  key: number,
  keyList: Array<number>,
  stringList: Array<string>
) => {
  if (!keyList) return "";
  if (!stringList) return "";
  for (let i = 0; i < keyList.length; i++) {
    if (keyList[i] === key) {
      if (stringList.length > i) return stringList[i];
      return "";
    }
  }
  return "";
};

export const getNumberArrayFromNumberArrayString = (
  // "24.1,25.9,0.0,1.7" 처럼 comma로 분리된 숫자가 나열된 string을 입력받아 number array를 생성해서 반환한다.
  arrayString: string
) => {
  if (!arrayString) return null;
  if (arrayString.length === 0) return null;
  let array: number[] = new Array(0);
  let splitted = arrayString.split(",");
  for (let i = 0; i < splitted.length; i++) {
    let fValue = parseFloat(splitted[i]);
    if (!Number.isNaN(fValue)) array.push(fValue);
  }
  if (array.length === 0) return null;
  return array;
};

export const containsInCommaSeparatedStringList = (
  // "박태민, 서지수, 이재현" 처럼 comma로 분리된 list에서 해당 항목이 포함되어 있는지 체크한다.
  // comma space 로 분리될 수도 있고 comma 로만 분리될 수도 있다.
  key: string,
  listString: string
) => {
  if (!listString) return false;
  if (!key) return false;
  if (key.length == 0) return false;
  let splitted = listString.split(",");
  for (let i = 0; i < splitted.length; i++) {
    // list에서 추출한 각 item에 대해 앞뒤의 space를 삭제한다.
    let str: string = splitted[i];
    for (let j = 0; j < splitted[i].length; j++) {
      if (splitted[i][j] === " ") str = str.substring(1);
      else break;
    }
    for (let j = splitted[i].length - 1; j >= 0; j--) {
      if (splitted[i][j] === " ") str = str.substring(0, str.length - 1);
      else break;
    }
    if (str === key) return true;
  }
  return false;
};

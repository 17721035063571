import "./cellNew.scss";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { alert, confirm } from "devextreme/ui/dialog";
import { readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
import * as U from "../../utils";

export default function CellNew() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [cellName, setCellName] = useState("");
  const [cellType, setCellType] = useState("");
  const [cellSize, setCellSize] = useState("");
  const [cellBuffer, setCellBuffer] = useState("");

  useEffect(() => {}, []);

  function onCellNameTextBoxValueChanged(e: string) {
    setCellName(e);
  }
  function onCellTypeTextBoxValueChanged(e: string) {
    setCellType(e);
  }
  function onCellSizeTextBoxValueChanged(e: string) {
    setCellSize(e);
  }
  function onCellBufferTextBoxValueChanged(e: string) {
    setCellBuffer(e);
  }

  async function onUploadButtonClicked() {
    if (cellName.length === 0) {
      alert("Enter cell name!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let cellSizeF: number = parseFloat(cellSize);
    if (Number.isNaN(cellSizeF)) cellSizeF = 0;

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let cellName2: string = U.replaceBrace(cellName);
    let cellType2: string = U.replaceBrace(cellType);
    let cellBuffer2: string = U.replaceBrace(cellBuffer);

    let obj: Object = {
      name: cellName2,
      type: cellType2,
      size: cellSizeF,
      buffer: cellBuffer2,
    };

    await uploadJson("/project/uploadcell", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Do you want to overwrite?", "Same name exists");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwritecell", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>New Cell</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Cell Name"
                value={cellName}
                valueChangeEvent="keyup"
                onValueChange={onCellNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cell Type"
                value={cellType}
                valueChangeEvent="keyup"
                onValueChange={onCellTypeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cell Size (um)"
                value={cellSize}
                valueChangeEvent="keyup"
                onValueChange={onCellSizeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cell Buffer"
                value={cellBuffer}
                valueChangeEvent="keyup"
                onValueChange={onCellBufferTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

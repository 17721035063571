import * as U from "../utils";

export const isProperTxId = (id: string) => {
  // 20220806-20010-1 (YYYYMMDD-[DeviceSeirial]-#) or 20220806-CS23001-1 형식에 맞는지 체크한다.
  if (!id) return false;
  let splitted = id.split("-");
  if (splitted.length != 3) return false;
  let dateStr = splitted[0];
  let deviceSerialStr = splitted[1];
  let numberStr: string = splitted[2];
  if (!U.isProperEightDigitStringDate(dateStr)) return false;
  if (deviceSerialStr.indexOf("CS") === 0) deviceSerialStr = deviceSerialStr.substring(2);
  if (deviceSerialStr.length != 5) return false;
  if (numberStr.length == 0) return false;
  if (!isInt(numberStr)) return false;
  return true;
};

export const isInt = (str: string) => {
  // 001 처럼 0으로 시작하는 숫자는 return false
  if (!str) return false;
  if (str.length == 0) return false;
  let nn: number = parseInt(str);
  if (Number.isNaN(nn)) return false;
  let str2: string = `${nn}`;
  if (str.length !== str2.length) return false;
  return true;
};

export const isFloat = (str: string) => {
  // . 을 기준으로 앞과 뒤로 나눈다.
  if (!str) return false;
  if (str.length == 0) return false;
  let splitted = str.split(".");
  if (2 < splitted.length) return false;
  if (splitted.length == 1) return isInt(str);
  else {
    // back은 "123", "001", "0" 등이 가능하다.
    let front: string = splitted[0];
    let back: string = splitted[1];
    if (!isInt(front)) return false;
    let back2 = "1" + back;
    if (back.length == 0) return false;
    if (!isInt(back2)) return false;
    return true;
  }
};

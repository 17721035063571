import { useAuth } from "./contexts/auth";
import * as U from "./utils";
// https://js.devexpress.com/Documentation/22_1/Guide/Themes_and_Styles/Icons/#Built-In_Icon_Library

type navigationType =
  | { text: string; path: string; icon: string; items?: undefined }
  | {
      text: string;
      path?: string;
      icon: string;
      items: { text: string; path: string }[];
    };
type itemType = { text: string; path: string };

export default function GetNavigation() {
  const { user } = useAuth();

  let acc_admin = 0,
    acc_tx = 0,
    acc_proj = 0,
    acc_facs = 0;
  if (user) {
    acc_admin = user.acc_admin;
    acc_tx = user.acc_tx;
    acc_proj = user.acc_proj;
    acc_facs = user.acc_facs;
  }

  let navigation = new Array<navigationType>();
  let items = new Array<itemType>();

  // Dashboard (페이지는 모두에게 보여주고 sector 별로 권한에 따라 보여줄 지 판단함)
  navigation.push({
    text: "Dashboard",
    path: "/dashboard",
    icon: "home",
  });

  // Project
  // (proj) [0] query for cell, mat, protocol, project
  if (U.bitAt(acc_proj, 0)) {
    navigation.push({
      text: "Projects",
      path: "/project",
      icon: "folder",
    });
  }

  // Protocol
  // (proj) [0] query for cell, mat, protocol, project
  if (U.bitAt(acc_proj, 0)) {
    navigation.push({
      text: "CellShot Protocols",
      path: "/protocol",
      icon: "toolbox",
    });
  }
  // Library
  // (proj) [0] query for cell, mat, protocol, project
  if (U.bitAt(acc_proj, 0)) {
    items = new Array<itemType>();
    items.push({
      text: "Cell",
      path: "/cell",
    });

    items.push({
      text: "Material",
      path: "/mat",
    });

    navigation.push({
      text: "Library",
      icon: "edit",
      items: items,
    });
  }

  // CellShot Results
  // (tx) [0] tx sample R & W,
  if (U.bitAt(acc_tx, 0)) {
    navigation.push({
      text: "CellShot Results",
      path: "/txSample",
      icon: "floppy",
    });
  }

  // Device
  // (tx) [2] device R & W
  if (U.bitAt(acc_tx, 2)) {
    navigation.push({
      text: "Device Monitoring",
      path: "/device",
      icon: "tips",
    });
  }

  // CoA
  // (tx) [5] Device cal & CoA W, [6] D, [7] Cartridge CoA W, [8] D
  if (true) {
    items = new Array<itemType>();
    if (U.bitAt(acc_tx, 5)) {
      items.push({
        text: "Device CoA Data",
        path: "/coa/device/data",
      });
    }
    items.push({
      text: "Device CoA",
      path: "/coa/device",
    });
    items.push({
      text: "Cartridge CoA",
      path: "/coa/cartridge",
    });
    navigation.push({
      text: "CoA",
      icon: "preferences",
      items: items,
    });
  }

  // Profile Category
  navigation.push({
    text: "Profile",
    path: "/profile",
    icon: "user",
  });

  return navigation;
}

import "./dashboard.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { queryJsonArray } from "../../api/auth";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import * as U from "../../utils";

export default function Dashboard() {
  const { user } = useAuth();

  const [activeProjectJsonData, setActiveProjectJsonData] = useState<Array<Object>>();
  const [deviceManagementJsonData, setDeviceManagementJsonData] = useState<Array<Object>>();

  const [isProjectDivHidden, setIsProjectDivHidden] = useState(true);
  const [isDeviceDivHidden, setIsDeviceDivHidden] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      if (U.bitAt(acc_admin, 0)) {
        setIsProjectDivHidden(false);
        setIsDeviceDivHidden(false);
        initialize();
      }
    }
  }, []);

  function initialize() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }
    let query = "SELECT * FROM device";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY updated_at Desc";
    queryJsonArray("/project/query", query).then((json: Array<Object>) => {
      // 마지막 메시지 수신 후 6분 이상 지났으면 status2 = Offline으로 표시해준다.
      let newJsonArray = new Array<Object>();
      let dateNow = new Date(Date.now());
      for (let i = 0; i < json.length; i++) {
        let updated_at = U.dateValueFromJson(json[i], "updated_at");
        let elapsedTime_ms = dateNow.getTime() - updated_at.getTime();
        let status2: string = U.stringValueFromJson(json[i], "status");
        if (360000 < elapsedTime_ms) status2 = "Offline";
        let obj: Object | null = U.addStringToJson(json[i], "status2", status2);
        if (obj !== null) newJsonArray.push(obj);
      }
      setDeviceManagementJsonData(newJsonArray);
    });

    // sample 결과로부터 active project에 대해 계산 후 결과를 테이블로 표시한다.
    query = "SELECT id, status, startDate, manager, numberOfUse, protocol FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY used_at Desc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonArray = new Array<Object>();
      for (let projectI = 0; projectI < projectJson.length; projectI++) {
        let status: string = U.stringValueFromJson(projectJson[projectI], "status");
        if (status === "Active") {
          //let obj: Object | null = U.addStringToJson(projectJson[projectI], "totalOperating", U.fixedPointLocaleStringFromFloat(totalOperating, 0));
          //obj = U.addStringToJson(obj, "blank", "-");
          //if (obj !== null) newJsonArray.push(obj);
          newJsonArray.push(projectJson[projectI]);
        }
      }
      setActiveProjectJsonData(newJsonArray);
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Dashboard</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}>
            <p className={"text-title"} hidden={isProjectDivHidden}>
              Active Project
            </p>
          </div>
          <div hidden={isProjectDivHidden}>
            <DataGrid dataSource={activeProjectJsonData} columnAutoWidth={true} allowColumnReordering={true}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="Project Name" />
              <Column dataField="status" caption="Status" />
              <Column dataField="protocol" caption="Protocol" />
              <Column dataField="used_at" caption="Used At" dataType="date" alignment={"center"} />
              <Column dataField="startDate" caption="Start Date" dataType="date" alignment={"center"} />
              <Column dataField="numberOfUse" caption="Number of Use" alignment={"center"} />
              <Column dataField="manager" caption="Project Manager" alignment={"center"} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"} hidden={isDeviceDivHidden}>
              Device Management
            </p>
          </div>
          <div hidden={isDeviceDivHidden}>
            <DataGrid dataSource={deviceManagementJsonData} columnAutoWidth={true} allowColumnReordering={true}>
              <Paging defaultPageSize={5} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="status2" caption="Status" />
              <Column dataField="userId" caption="User ID" />
              <Column dataField="updated_at" caption="Last Update" dataType="datetime" />
              <Column dataField="project" caption="Project" />
              <Column dataField="model" caption="Model" />
              <Column dataField="swVersion" caption="SW Version" />
              <Column dataField="location" caption="Location" />

              <Summary>
                <TotalItem column="deviceSerial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}

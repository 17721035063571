import { createContext, useContext } from "react";
export type ProtocolContextType = {
  id: string;
  cartridge: string;
  outflow: string;
  mixingRatio: string;
  freq: string;
  voltage: string;
  duty: string;
  cooler: string;
  maxVolume: string;
  harvestVolume: string;
  startDate: string;
  numberOfUse: string;
};
const ProtocolContext = createContext<ProtocolContextType>({} as ProtocolContextType);
const useProtocol = () => useContext(ProtocolContext);
export { useProtocol };

import "./txReport.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCommon } from "../../contexts/common";
// api
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
} from "devextreme-react/chart";

export default function TxReport() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const [userId, setUserId] = useState("");

  const [txId, setTxId] = useState("");

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [summaryJsonDataSource, setSummaryJsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_tx = 0;
    if (user) {
      setUserId(user.id);
      acc_tx = user.acc_tx;
    }
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      setTxId(commonContext.str1);
      search(commonContext.str1);
    }
  }, []);

  function search(txId: string) {
    let query = "SELECT * FROM sample";
    query += ` WHERE txId = '${txId}'`;
    queryJsonArray("/tx/querysample", query).then((sampleJsonData: Array<Object>) => {
      if (sampleJsonData.length !== 1) {
        alert("No data!", "Error");
        return;
      }
      let oneRowJson: Object | null = new Object();

      // Summary
      let txId: string = U.stringValueFromJson(sampleJsonData[0], "txId");
      let txTime: Date = U.dateValueFromJson(sampleJsonData[0], "txTime");
      let project: string = U.stringValueFromJson(sampleJsonData[0], "project");
      let note: string = U.stringValueFromJson(sampleJsonData[0], "note");
      let deviceModel: string = U.stringValueFromJson(sampleJsonData[0], "deviceModel");
      let cartridge: string = U.stringValueFromJson(sampleJsonData[0], "cartridge");
      let txUserId: string = U.stringValueFromJson(sampleJsonData[0], "txUserId");
      let protocol: string = U.stringValueFromJson(sampleJsonData[0], "protocol");
      let freq: number = U.floatValueFromJson(sampleJsonData[0], "freq");
      let voltage: number = U.floatValueFromJson(sampleJsonData[0], "voltage");
      let duty: number = U.floatValueFromJson(sampleJsonData[0], "duty");
      let outflow: number = U.floatValueFromJson(sampleJsonData[0], "outflow");
      let mixingRatio: number = U.floatValueFromJson(sampleJsonData[0], "mixingRatio");
      let cooler: number = U.floatValueFromJson(sampleJsonData[0], "cooler");
      let harvestVolume: number = U.floatValueFromJson(sampleJsonData[0], "harvestVolume");
      let runningTime: number = U.floatValueFromJson(sampleJsonData[0], "runningTime");
      let cellName: string = U.stringValueFromJson(sampleJsonData[0], "cellName");
      let cellBuffer: string = U.stringValueFromJson(sampleJsonData[0], "cellBuffer");
      let cellConc: number = U.floatValueFromJson(sampleJsonData[0], "cellConc");
      let matName: string = U.stringValueFromJson(sampleJsonData[0], "matName");
      let matBuffer: string = U.stringValueFromJson(sampleJsonData[0], "matBuffer");
      let matConc: number = U.floatValueFromJson(sampleJsonData[0], "matConc");

      let summaryJsonData = new Array<Object>();
      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX ID");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txId);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX Time");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txTime.toLocaleDateString() + ", " + txTime.toLocaleTimeString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Project");
      oneRowJson = U.addStringToJson(oneRowJson, "value", project);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Protocol");
      oneRowJson = U.addStringToJson(oneRowJson, "value", protocol);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Note");
      oneRowJson = U.addStringToJson(oneRowJson, "value", note);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Device Model");
      oneRowJson = U.addStringToJson(oneRowJson, "value", deviceModel);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cartridge");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cartridge);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "TX User ID");
      oneRowJson = U.addStringToJson(oneRowJson, "value", txUserId);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Frequency (kHz)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", freq.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Voltage (V)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", voltage.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Duty (%)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", duty.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Outflow (mL/h)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", outflow.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mixing Ratio(M/C)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", mixingRatio.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cooler (%)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cooler.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Harvest Volume (mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", harvestVolume.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Running Time (min)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", runningTime.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Name");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellName);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Buffer");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellBuffer);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Cell Conc. (10^6 cells/mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", cellConc.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Name");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matName);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Buffer");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matBuffer);
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      oneRowJson = new Object();
      oneRowJson = U.addStringToJson(oneRowJson, "title", "Mat. Conc. (ug/mL)");
      oneRowJson = U.addStringToJson(oneRowJson, "value", matConc.toString());
      if (oneRowJson !== null) summaryJsonData.push(oneRowJson);

      setSummaryJsonDataSource(summaryJsonData);

      // Chart Data
      let dt: number = U.floatValueFromJson(sampleJsonData[0], "dt");
      let data1: string = U.stringValueFromJson(sampleJsonData[0], "data1");
      let data2: string = U.stringValueFromJson(sampleJsonData[0], "data2");
      let data3: string = U.stringValueFromJson(sampleJsonData[0], "data3");
      let data4: string = U.stringValueFromJson(sampleJsonData[0], "data4");
      let data5: string = U.stringValueFromJson(sampleJsonData[0], "data5");
      let data6: string = U.stringValueFromJson(sampleJsonData[0], "data6");
      let data7: string = U.stringValueFromJson(sampleJsonData[0], "data7");
      let data1Array = U.getNumberArrayFromNumberArrayString(data1);
      let data2Array = U.getNumberArrayFromNumberArrayString(data2);
      let data3Array = U.getNumberArrayFromNumberArrayString(data3);
      let data4Array = U.getNumberArrayFromNumberArrayString(data4);
      let data5Array = U.getNumberArrayFromNumberArrayString(data5);
      let data6Array = U.getNumberArrayFromNumberArrayString(data6);
      let data7Array = U.getNumberArrayFromNumberArrayString(data7);
      if (!data1Array || !data2Array || !data3Array || !data4Array || !data5Array || !data6Array || !data7Array) {
        alert("No chart data!", "Error");
        return;
      }
      let data1N: number = data1Array.length;
      let data2N: number = data2Array.length;
      let data3N: number = data3Array.length;
      let data4N: number = data4Array.length;
      let data5N: number = data5Array.length;
      let data6N: number = data6Array.length;
      let data7N: number = data7Array.length;
      let dataN: number = data1N;
      if (data2N < dataN) dataN = data2N;
      if (data3N < dataN) dataN = data3N;
      if (data4N < dataN) dataN = data4N;
      if (data5N < dataN) dataN = data5N;
      if (data6N < dataN) dataN = data6N;
      if (data7N < dataN) dataN = data7N;
      if (dataN === 0) {
        alert("No chart data!", "Error");
        return;
      }

      // Chart Data 생성
      let jsonData = new Array<Object>();
      for (let i = 0; i < dataN; i++) {
        oneRowJson = new Object();
        oneRowJson = U.addNumberToJson(oneRowJson, "x", (i * dt) / 60.0);
        oneRowJson = U.addNumberToJson(oneRowJson, "y1", data1Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y2", data2Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y3", data3Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y4", data4Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y5", data5Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y6", data6Array[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "y7", data7Array[i]);
        if (oneRowJson !== null) jsonData.push(oneRowJson);
      }
      setJsonDataSource(jsonData);
    });
  }

  function onDataGridSelectionChanged(e: any) {}

  return (
    <React.Fragment>
      <h2 className={"content-block"}>TX Report (Temporary)</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div>
              <DataGrid dataSource={summaryJsonDataSource} columnAutoWidth={true} allowColumnReordering={true} showColumnHeaders={false}>
                <Paging defaultPageSize={25} />
                <LoadPanel enabled />
                <Selection mode="single" />
                <Column dataField="title" caption="Title" />
                <Column dataField="value" caption="Value" />
              </DataGrid>
            </div>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Current"}>
              <CommonSeriesSettings argumentField="x" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y1"} name={"Current (mA)"} />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Current (mA)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#.###"}></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Temperature"}>
              <CommonSeriesSettings argumentField="x" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y2"} name={"T_cold"} color="#6D9CC6" />
              <Series valueField={"y3"} name={"T_hot"} color="#FF0000" />
              <Series valueField={"y4"} name={"T_mb"} color="#F8BA63" />
              <Series valueField={"y5"} name={"T_ir"} color="#B273CE" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Temperature (C)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={"Syringe Pressure"}>
              <CommonSeriesSettings argumentField="x" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"y6"} name={"Mat."} color="#6D9CC6" />
              <Series valueField={"y7"} name={"Cell"} color="#FF0000" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Time (min)"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              ></ArgumentAxis>

              <ValueAxis title={"Push Force (kgf)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494" format={"#.###"}></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="x" caption="Time (min)" format={"0.###"} />
              <Column dataField="y1" caption="Current" />
              <Column dataField="y2" caption="T_cold (C)" />
              <Column dataField="y3" caption="T_hot (C)" />
              <Column dataField="y4" caption="T_mb (C)" />
              <Column dataField="y5" caption="T_ir (C)" />
              <Column dataField="y6" caption="Mat. (kgf)" />
              <Column dataField="y7" caption="Cell (kgf)" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="x" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

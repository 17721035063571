import "./projectNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProject } from "../../contexts/project";
// api
import { queryJsonArray, deleteRowById, readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import Switch from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { alert, confirm } from "devextreme/ui/dialog";

export default function ProjectNew() {
  const { user } = useAuth();
  const projectContext = useProject();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("New Project");

  const [projectNameList, setProjectNameList] = useState<Array<string>>([]);
  const [projectName, setProjectName] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [strStartDate, setStrStartDate] = useState("");
  const [numberOfUse, setNumberOfUse] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [cellName, setCellName] = useState("");
  const [cellNameList, setCellNameList] = useState<Array<string>>([]);
  const [isCellNameSelectBoxHidden, setIsCellNameSelectBoxHidden] = useState(false);
  const [isCellNameTextBoxHidden, setIsCellNameTextBoxHidden] = useState(true);
  const [cellType, setCellType] = useState("");
  const [cellTypeList, setCellTypeList] = useState<Array<string>>([]);
  const [cellSize, setCellSize] = useState("");
  const [cellSizeList, setCellSizeList] = useState<Array<string>>([]);
  const [cellBuffer, setCellBuffer] = useState("");
  const [cellBufferList, setCellBufferList] = useState<Array<string>>([]);
  const [cellConc, setCellConc] = useState("");
  const [matName, setMatName] = useState("");
  const [matNameList, setMatNameList] = useState<Array<string>>([]);
  const [isMatNameSelectBoxHidden, setIsMatNameSelectBoxHidden] = useState(false);
  const [isMatNameTextBoxHidden, setIsMatNameTextBoxHidden] = useState(true);
  const [matType, setMatType] = useState("");
  const [matTypeList, setMatTypeList] = useState<Array<string>>([]);
  const [matMolWeight, setMatMolWeight] = useState("");
  const [matMolWeightList, setMatMolWeightList] = useState<Array<string>>([]);
  const [matBuffer, setMatBuffer] = useState("");
  const [matBufferList, setMatBufferList] = useState<Array<string>>([]);
  const [matConc, setMatConc] = useState("");

  const [protocolName, setProtocolName] = useState("");
  const [protocolNameList, setProtocolNameList] = useState<Array<string>>([]);
  const [isProtocolNameSelectBoxHidden, setIsProtocolNameSelectBoxHidden] = useState(false);
  const [isProtocolNameTextBoxHidden, setIsProtocolNameTextBoxHidden] = useState(true);
  const [cartridge, setCartridge] = useState("");
  const [cartridgeList, setCartridgeList] = useState<Array<string>>([]);
  const [outflow, setOutflow] = useState("");
  const [outflowList, setOutflowList] = useState<Array<string>>([]);
  const [mixingRatio, setMixingRatio] = useState("");
  const [mixingRatioList, setMixingRatioList] = useState<Array<string>>([]);
  const [freq, setFreq] = useState("");
  const [freqList, setFreqList] = useState<Array<string>>([]);
  const [voltage, setVoltage] = useState("");
  const [voltageList, setVoltageList] = useState<Array<string>>([]);
  const [duty, setDuty] = useState("");
  const [dutyList, setDutyList] = useState<Array<string>>([]);
  const [cooler, setCooler] = useState("");
  const [coolerList, setCoolerList] = useState<Array<string>>([]);
  const [maxVolume, setMaxVolume] = useState("");
  const [maxVolumeList, setMaxVolumeList] = useState<Array<string>>([]);
  const [harvestVolume, setHarvestVolume] = useState("");
  const [harvestVolumeList, setHarvestVolumeList] = useState<Array<string>>([]);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let userId: string = "";
    if (user) {
      userId = user.id;
    }
    setStatus("Active");

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
    }

    // SelectBox의 값을 설정할 때에는 dataSource의 list 안에 있는 값만 적용된다. 다른 값을 설정하면 화면에는 선택 안된 걸로 표시된다.
    if (0 < projectContext.id.length) {
      // edit
      setPageTitle("Edit Project");
      setProjectName(projectContext.id);
      setStatus(projectContext.status);
      setProjectManager(projectContext.manager);
      setStartDate(projectContext.startDate);
      setStrStartDate(U.eightDigitDotStringDateFromDate(projectContext.startDate));
      setNumberOfUse(projectContext.numberOfUse);
      setCellName(projectContext.cellName);
      setIsCellNameSelectBoxHidden(true);
      setIsCellNameTextBoxHidden(false);
      setCellType(projectContext.cellType);
      setCellSize(projectContext.cellSize);
      setCellBuffer(projectContext.cellBuffer);
      setCellConc(projectContext.cellConc);
      setMatName(projectContext.matName);
      setIsMatNameSelectBoxHidden(true);
      setIsMatNameTextBoxHidden(false);
      setMatType(projectContext.matType);
      setMatMolWeight(projectContext.matMolWeight);
      setMatBuffer(projectContext.matBuffer);
      setMatConc(projectContext.matConc);

      setProtocolName(projectContext.protocol);
      setIsProtocolNameSelectBoxHidden(true);
      setIsProtocolNameTextBoxHidden(false);
      setCartridge(projectContext.cartridge);
      setOutflow(projectContext.outflow);
      setMixingRatio(projectContext.mixingRatio);
      setFreq(projectContext.freq);
      setVoltage(projectContext.voltage);
      setDuty(projectContext.duty);
      let coolerF: number = U.floatFromString(projectContext.cooler, 0);
      if (coolerF == 0) setCooler("Off");
      else setCooler("On");
      let maxVolumeN: number = U.intFromString(projectContext.maxVolume, 0);
      if (maxVolumeN == 0) {
        setMaxVolume("Off");
      } else {
        setMaxVolume("On");
      }
      setHarvestVolume(projectContext.harvestVolume);
      setIsCellNameSelectBoxHidden(true);
      setIsCellNameTextBoxHidden(false);
      setIsMatNameSelectBoxHidden(true);
      setIsMatNameTextBoxHidden(false);
      setIsProtocolNameSelectBoxHidden(true);
      setIsProtocolNameTextBoxHidden(false);
    } else {
      setPageTitle("New Project");
      setProjectManager(userId);
      setStartDate(new Date());
      setStrStartDate(U.eightDigitDotStringDateFromDate(new Date()));
      setNumberOfUse("0");
      setIsCellNameSelectBoxHidden(false);
      setIsCellNameTextBoxHidden(true);
      setIsMatNameSelectBoxHidden(false);
      setIsMatNameTextBoxHidden(true);
      setIsProtocolNameSelectBoxHidden(false);
      setIsProtocolNameTextBoxHidden(true);
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    // cell, mat은 같이 연산할 내용은 없으므로 각자 query 및 then 처리를 해준다.
    let query = "SELECT name, type, size, buffer FROM cell";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY name Asc";
    queryJsonArray("/project/query", query).then((cellJson: Array<Object>) => {
      let nameList = [];
      let typeList = [];
      let sizeList = [];
      let bufferList = [];
      for (let i = 0; i < cellJson.length; i++) {
        nameList.push(U.stringValueFromJson(cellJson[i], "name"));
        typeList.push(U.stringValueFromJson(cellJson[i], "type"));
        sizeList.push(U.floatValueFromJson(cellJson[i], "size").toString());
        bufferList.push(U.stringValueFromJson(cellJson[i], "buffer"));
      }
      setCellNameList(nameList);
      setCellTypeList(typeList);
      setCellSizeList(sizeList);
      setCellBufferList(bufferList);
    });

    query = "SELECT name, type, molWeight, buffer FROM mat";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY name Asc";
    queryJsonArray("/project/query", query).then((matJson: Array<Object>) => {
      let nameList = [];
      let typeList = [];
      let molWeightList = [];
      let bufferList = [];
      for (let i = 0; i < matJson.length; i++) {
        nameList.push(U.stringValueFromJson(matJson[i], "name"));
        typeList.push(U.stringValueFromJson(matJson[i], "type"));
        molWeightList.push(U.floatValueFromJson(matJson[i], "molWeight").toString());
        bufferList.push(U.stringValueFromJson(matJson[i], "buffer"));
      }
      setMatNameList(nameList);
      setMatTypeList(typeList);
      setMatMolWeightList(molWeightList);
      setMatBufferList(bufferList);
    });

    query = "SELECT id, cartridge, outflow, mixingRatio, freq, voltage, duty, cooler, maxVolume, harvestVolume FROM protocol";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/query", query).then((protocolJson: Array<Object>) => {
      let idList = [];
      let cartridgeList = [];
      let outflowList = [];
      let mixingRatioList = [];
      let freqList = [];
      let voltageList = [];
      let dutyList = [];
      let coolerList = [];
      let maxVolumeList = [];
      let harvestVolumeList = [];
      for (let i = 0; i < protocolJson.length; i++) {
        idList.push(U.stringValueFromJson(protocolJson[i], "id"));
        cartridgeList.push(U.stringValueFromJson(protocolJson[i], "cartridge"));
        outflowList.push(U.floatValueFromJson(protocolJson[i], "outflow").toString());
        mixingRatioList.push(U.floatValueFromJson(protocolJson[i], "mixingRatio").toString());
        freqList.push(U.floatValueFromJson(protocolJson[i], "freq").toString());
        voltageList.push(U.floatValueFromJson(protocolJson[i], "voltage").toString());
        dutyList.push(U.floatValueFromJson(protocolJson[i], "duty").toString());
        coolerList.push(U.floatValueFromJson(protocolJson[i], "cooler").toString());
        maxVolumeList.push(U.intValueFromJson(protocolJson[i], "maxVolume").toString());
        harvestVolumeList.push(U.floatValueFromJson(protocolJson[i], "harvestVolume").toString());
      }
      setProtocolNameList(idList);
      setCartridgeList(cartridgeList);
      setOutflowList(outflowList);
      setMixingRatioList(mixingRatioList);
      setFreqList(freqList);
      setVoltageList(voltageList);
      setDutyList(dutyList);
      setCoolerList(coolerList);
      setMaxVolumeList(maxVolumeList);
      setHarvestVolumeList(harvestVolumeList);
    });

    query = "SELECT id FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/query", query).then((jsonData: Array<Object>) => {
      let nameList = [];
      for (let i = 0; i < jsonData.length; i++) {
        nameList.push(U.stringValueFromJson(jsonData[i], "id"));
      }
      setProjectNameList(nameList);
    });
  }

  function onStatusSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setStatus(str);
  }

  function updateProjectName(currentCellName: string, currentMatName: string) {
    // CHO-K1-pGFP(PBS)-2409001
    let date = new Date();
    let year: number = date.getFullYear(); // 2024
    let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
    let strYear: string = year.toString();
    let yy: string = strYear.substring(2, 4);
    let strMonth: string = month.toString();
    let mm: string = "";
    if (strMonth.length == 1) mm = "0";
    mm += strMonth;
    let yymm: string = yy + mm;

    let prefix: string = `${currentCellName}-${currentMatName}-${yymm}`; // 마지막 NNN 3자리 제외한 앞부분

    let maxIndex: number = 0;
    for (let i = 0; i < projectNameList.length; i++) {
      let listName: string = projectNameList[i];
      if (listName.length < 9) continue;
      let listYymm: string = listName.substring(listName.length - 7, listName.length - 3);
      let listIndex: string = listName.substring(listName.length - 3, listName.length);
      if (yymm === listYymm) {
        let index: number = U.intFromString(listIndex, 0);
        if (maxIndex < index) maxIndex = index;
      }
    }
    let strNewIndex: string = "";
    let newIndex: number = maxIndex + 1;
    if (newIndex < 10) strNewIndex = "00" + newIndex.toString();
    else {
      if (newIndex < 100) strNewIndex = "0" + newIndex.toString();
      else strNewIndex = newIndex.toString();
    }
    if (strNewIndex.length > 3) strNewIndex = "999";
    let newName: string = `${prefix}${strNewIndex}`;
    setProjectName(newName);
  }

  function onCellNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCellName(str);
    let index: number = U.indexOfStringArray(str, cellNameList);
    if (index < 0) return;
    setCellType(cellTypeList[index]);
    setCellSize(cellSizeList[index]);
    setCellBuffer(cellBufferList[index]);

    updateProjectName(str, matName);
  }
  function onCellConcTextBoxValueChanged(e: string) {
    setCellConc(e);
  }
  function onMatNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setMatName(str);
    let index: number = U.indexOfStringArray(str, matNameList);
    if (index < 0) return;
    setMatType(matTypeList[index]);
    setMatMolWeight(matMolWeightList[index]);
    setMatBuffer(matBufferList[index]);

    updateProjectName(cellName, str);
  }
  function onMatConcTextBoxValueChanged(e: string) {
    setMatConc(e);
  }

  function onProtocolNameSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setProtocolName(str);
    let index: number = U.indexOfStringArray(str, protocolNameList);
    if (index < 0) return;
    setCartridge(cartridgeList[index]);
    setOutflow(outflowList[index]);
    setMixingRatio(mixingRatioList[index]);
    setFreq(freqList[index]);
    setVoltage(voltageList[index]);
    setDuty(dutyList[index]);
    let strCooler: string = "On";
    if (coolerList[index] === "0") strCooler = "Off";
    setCooler(strCooler);
    let strMaxVolume: string = "On";
    if (maxVolumeList[index] === "0") strMaxVolume = "Off";
    setMaxVolume(strMaxVolume);
    setHarvestVolume(harvestVolumeList[index]);
  }

  async function onUploadButtonClicked() {
    if (cellName.length === 0) {
      alert("Select cell name!", "Error");
      return;
    }
    if (matName.length === 0) {
      alert("Select mat name!", "Error");
      return;
    }
    if (protocolName.length === 0) {
      alert("Enter protocol name!", "Error");
      return;
    }
    if (projectName.length === 0) {
      alert("Enter project name!", "Error");
      return;
    }
    if (cartridge.length === 0) {
      alert("Select cartridge!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let cellConcF: number = U.floatFromString(cellConc, 0);
    if (cellConcF < 0) {
      alert("Enter proper cell conc. value!", "Error");
      return;
    }

    let matConcF: number = U.floatFromString(matConc, 0);
    if (matConcF < 0) {
      alert("Enter proper mat conc. value!", "Error");
      return;
    }

    let cellSizeF: number = U.floatFromString(cellSize, 0);
    let matMolWeightF: number = U.floatFromString(matMolWeight, 0);
    let outflowF: number = U.floatFromString(outflow, 0);
    let mixingRatioF: number = U.floatFromString(mixingRatio, 0);
    let freqF: number = U.floatFromString(freq, 0);
    if (freqF !== 1 && freqF !== 2 && freqF !== 3 && freqF !== 4 && freqF !== 5 && freqF !== 6 && freqF !== 7 && freqF !== 8 && freqF !== 9 && freqF !== 10) {
      alert("Enter integer value between 1 ~ 10 for frequency!", "Error");
      return;
    }
    let voltageF: number = U.floatFromString(voltage, 0);
    let dutyF: number = U.floatFromString(duty, 0);
    let coolerF: number = 100;
    if (cooler === "Off") coolerF = 0;
    let harvestVolumeF: number = U.floatFromString(harvestVolume, 0);
    let maxVolumeN: number = 1;
    if (maxVolume === "Off") maxVolumeN = 0;
    else harvestVolumeF = 0;

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let projectName2: string = U.replaceBrace(projectName);

    let obj: Object = {
      id: projectName2,
      status: status,
      manager: projectManager,
      startDate: startDate,
      numberOfUse: numberOfUse,
      cellName: cellName,
      cellType: cellType,
      cellSize: cellSizeF,
      cellBuffer: cellBuffer,
      cellConc: cellConcF,
      matName: matName,
      matType: matType,
      matMolWeight: matMolWeightF,
      matBuffer: matBuffer,
      matConc: matConcF,
      protocol: protocolName,
      cartridge: cartridge,
      outflow: outflowF,
      mixingRatio: mixingRatioF,
      freq: freqF,
      voltage: voltageF,
      duty: dutyF,
      cooler: coolerF,
      maxVolume: maxVolumeN,
      harvestVolume: harvestVolumeF,
    };

    await uploadJson("/project/uploadproject", obj).then((response_status: number) => {
      //console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Same id exists! Do you want to overwrite?", "Warning");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwriteproject", obj);
            navigate(-1);
          } else return;
        });
      } else {
        if (response_status === 23) {
          // 23: Row with same major properties exists when upload new item
          let res = confirm("Project with same cell, mat and protocol exists! Continue?", "Warning");
          res.then((dialogResult) => {
            if (dialogResult) {
              // Yes
              uploadJsonAndAlert("/project/overwriteproject", obj);
              navigate(-1);
            } else return;
          });
        } else {
          alert("Failed to upload!", "Error");
          return;
        }
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Project Name" value={projectName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <SelectBox label="Status" dataSource={["Active", "Inactive"]} value={status} width={300} onSelectionChanged={onStatusSelectionChanged} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Project Manager" value={projectManager} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Start Date" value={strStartDate} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Number of Use" value={numberOfUse} readOnly={true} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Cell</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isCellNameSelectBoxHidden}>
              <SelectBox label="Cell Name" dataSource={cellNameList} value={cellName} width={300} onSelectionChanged={onCellNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isCellNameTextBoxHidden}>
              <TextBox label="Cell Name" value={cellName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Type" value={cellType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Size (um)" value={cellSize} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Buffer" value={cellBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cell Conc. (10^6 cells/mL)"
                value={cellConc}
                valueChangeEvent="keyup"
                onValueChange={onCellConcTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Material</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isMatNameSelectBoxHidden}>
              <SelectBox label="Mat Name" dataSource={matNameList} value={matName} width={300} onSelectionChanged={onMatNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isMatNameTextBoxHidden}>
              <TextBox label="Mat Name" value={matName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Type" value={matType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Mol. Weight (kDa)" value={matMolWeight} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Buffer" value={matBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mat Conc. (ug/mL)"
                value={matConc}
                valueChangeEvent="keyup"
                onValueChange={onMatConcTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>Protocol</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={isProtocolNameSelectBoxHidden}>
              <SelectBox label="Protocol" dataSource={protocolNameList} value={protocolName} width={300} onSelectionChanged={onProtocolNameSelectionChanged} />
            </div>

            <div className={"flex-item2"} hidden={isProtocolNameTextBoxHidden}>
              <TextBox label="Protocol" value={protocolName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cartridge" value={cartridge} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Outflow (mL/h)" value={outflow} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mixing Ratio (Mat/Cell)" value={mixingRatio} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Frequency (kHz)" value={freq} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Voltage (V)" value={voltage} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Duty (%)" value={duty} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cooler" value={cooler} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Max Volume" value={maxVolume} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Harvest Volume (mL)" value={harvestVolume} readOnly={true} width={300} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import "./loginPage.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
//import { login } from "../../api/auth";

// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

import { Cookies } from "react-cookie";

const cookies = new Cookies();

export default function LoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [affiliation, setAffiliation] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;
  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let cookie_affiliation: string = cookies.get("userAffiliation");
    if (cookie_affiliation === undefined) cookie_affiliation = "";
    setAffiliation(cookie_affiliation);
  }, []);

  function onAffiliationTextBoxValueChanged(e: string) {
    setAffiliation(e);
  }
  function onIdTextBoxValueChanged(e: string) {
    setId(e);
  }
  function onPasswordTextBoxValueChanged(e: string) {
    setPassword(e);
  }
  function onPasswordTextBoxEnterKey() {
    onLoginButtonClicked();
  }

  ///////////////////////////// Upload Button
  async function onLoginButtonClicked() {
    if (affiliation.length === 0) {
      alert("Enter affiliation!", "Error");
      return;
    }
    if (id.length === 0) {
      alert("Enter ID!", "Error");
      return;
    }
    if (password.length === 0) {
      alert("Enter Password!", "Error");
      return;
    }

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let affiliation2: string = U.replaceBrace(affiliation);
    let id2: string = U.replaceBrace(id);

    setLoading(true);
    const result = await login(affiliation2, id2, password);
    if (!result.isOk) {
      if (result.message) {
        alert(result.message, "Error");
      }
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className={"login-containerV"}>
        <div className={"login-label"}>Affiliation</div>
        <div className={"login-item1"}>
          <TextBox value={affiliation} valueChangeEvent="keyup" onValueChange={onAffiliationTextBoxValueChanged} showClearButton={false} />
        </div>

        <div className={"login-label"}>ID</div>
        <div className={"login-item1"}>
          <TextBox value={id} valueChangeEvent="keyup" onValueChange={onIdTextBoxValueChanged} showClearButton={false} />
        </div>

        <div className={"login-label"}>Password</div>
        <div className={"login-item1"}>
          <TextBox
            value={password}
            mode="password"
            onEnterKey={onPasswordTextBoxEnterKey}
            valueChangeEvent="keyup"
            onValueChange={onPasswordTextBoxValueChanged}
            showClearButton={false}
          />
        </div>

        <div className={"login-item2"}>
          <Button text="Login" onClick={onLoginButtonClicked} width={250} type="default" />
        </div>
      </div>
    </React.Fragment>
  );
}

import "./home.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";

export default function NewPage() {
  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>FACS Analyzer SW</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <a href="/downloadinstaller">Download CellShot FACS Analyzer Installer (for Windows)</a>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}

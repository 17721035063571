import "./txSampleNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { alert, confirm } from "devextreme/ui/dialog";

export default function NewTxSample() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [txId, setTxId] = useState("");
  const [txTime, setTxTime] = useState(new Date());
  const [deviceModel, setDeviceModel] = useState("");
  let currentDeviceModel: string = "";
  const [deviceSerial, setDeviceSerial] = useState("");
  const [deviceAmpType, setDeviceAmpType] = useState("");
  const [fivePHidden, setFivePHidden] = useState(true);
  const [project, setProject] = useState("");
  const [projectList, setProjectList] = useState<Array<string>>([]);
  const [cellName, setCellName] = useState("");
  const [cellNameList, setCellNameList] = useState<Array<string>>([]);
  const [cellType, setCellType] = useState("");
  const [cellTypeList, setCellTypeList] = useState<Array<string>>([]);
  const [cellSize, setCellSize] = useState("");
  const [cellSizeList, setCellSizeList] = useState<Array<string>>([]);
  const [cellBuffer, setCellBuffer] = useState("");
  const [cellBufferList, setCellBufferList] = useState<Array<string>>([]);
  const [cellConc, setCellConc] = useState("");
  const [cellConcList, setCellConcList] = useState<Array<string>>([]);
  const [matName, setMatName] = useState("");
  const [matNameList, setMatNameList] = useState<Array<string>>([]);
  const [matType, setMatType] = useState("");
  const [matTypeList, setMatTypeList] = useState<Array<string>>([]);
  const [matMolWeight, setMatMolWeight] = useState("");
  const [matMolWeightList, setMatMolWeightList] = useState<Array<string>>([]);
  const [matBuffer, setMatBuffer] = useState("");
  const [matBufferList, setMatBufferList] = useState<Array<string>>([]);
  const [matConc, setMatConc] = useState("");
  const [matConcList, setMatConcList] = useState<Array<string>>([]);
  const [cartridge, setCartridge] = useState("");
  const [cartridgeList, setCartridgeList] = useState<Array<string>>([]);
  const [mixingRatio, setMixingRatio] = useState("");
  const [mixingRatioList, setMixingRatioList] = useState<Array<string>>([]);
  const [outflow, setOutflow] = useState("");
  const [outflowList, setOutflowList] = useState<Array<string>>([]);
  const [voltage, setVoltage] = useState("");
  const [voltageList, setVoltageList] = useState<Array<string>>([]);
  const [duty, setDuty] = useState("");
  const [dutyList, setDutyList] = useState<Array<string>>([]);

  const [txUserName, setTxUserName] = useState("");
  const [harvestVolume, setHarvestVolume] = useState("");
  const [current, setCurrent] = useState("");
  const [cellShotStability, setCellShotStability] = useState("");
  const [runningTime, setRunningTime] = useState("");
  const [errorCase, setErrorCase] = useState(false);
  const [remark, setRemark] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    currentDeviceModel = "3P TUI";
    setDeviceModel("3P TUI");
    updateAsCurrentDeviceModel();
    if (user) {
    }

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    // cell, mat, protocol은 같이 연산할 내용은 없으므로 각자 query 및 then 처리를 해준다.
    let query = "SELECT * FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let projectL = [];
      let cellNameL = [];
      let cellTypeL = [];
      let cellSizeL = [];
      let cellBufferL = [];
      let cellConcL = [];
      let matNameL = [];
      let matTypeL = [];
      let matMolWeightL = [];
      let matBufferL = [];
      let matConcL = [];
      let cartridgeL = [];
      let outflowL = [];
      let mixingRatioL = [];
      let voltageL = [];
      let dutyL = [];
      for (let i = 0; i < projectJson.length; i++) {
        projectL.push(U.stringValueFromJson(projectJson[i], "id"));
        cellNameL.push(U.stringValueFromJson(projectJson[i], "cellName"));
        cellTypeL.push(U.stringValueFromJson(projectJson[i], "cellType"));
        cellSizeL.push(U.floatValueFromJson(projectJson[i], "cellSize").toString());
        cellBufferL.push(U.stringValueFromJson(projectJson[i], "cellBuffer"));
        cellConcL.push(U.floatValueFromJson(projectJson[i], "cellConc").toString());
        matNameL.push(U.stringValueFromJson(projectJson[i], "matName"));
        matTypeL.push(U.stringValueFromJson(projectJson[i], "matType"));
        matMolWeightL.push(U.floatValueFromJson(projectJson[i], "matMolWeight").toString());
        matBufferL.push(U.stringValueFromJson(projectJson[i], "matBuffer"));
        matConcL.push(U.floatValueFromJson(projectJson[i], "matConc").toString());
        cartridgeL.push(U.stringValueFromJson(projectJson[i], "cartridge"));
        outflowL.push(U.stringValueFromJson(projectJson[i], "outflow"));
        mixingRatioL.push(U.stringValueFromJson(projectJson[i], "mixingRatio"));
        voltageL.push(U.stringValueFromJson(projectJson[i], "voltage"));
        dutyL.push(U.stringValueFromJson(projectJson[i], "duty"));
      }
      setProjectList(projectL);
      setCellNameList(cellNameL);
      setCellTypeList(cellTypeL);
      setCellSizeList(cellSizeL);
      setCellBufferList(cellBufferL);
      setCellConcList(cellConcL);
      setMatNameList(matNameL);
      setMatTypeList(matTypeL);
      setMatMolWeightList(matMolWeightL);
      setMatBufferList(matBufferL);
      setMatConcList(matConcL);
      setCartridgeList(cartridgeL);
      setOutflowList(outflowL);
      setMixingRatioList(mixingRatioL);
      setVoltageList(voltageL);
      setDutyList(dutyL);
    });
  }

  function onTxIdTextBoxValueChanged(e: string) {
    setTxId(e);
  }
  function onTxTimeDateBoxValueChanged(e: Date) {
    setTxTime(e);
  }
  function onDeviceModelSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDeviceModel(str);
    currentDeviceModel = str;
    updateAsCurrentDeviceModel();
  }
  function onProjectSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setProject(str);
    let index: number = U.indexOfStringArray(str, projectList);
    if (index < 0) return;
    setCellName(cellNameList[index]);
    setCellType(cellTypeList[index]);
    setCellSize(cellSizeList[index]);
    setCellBuffer(cellBufferList[index]);
    setCellConc(cellConcList[index]);
    setMatName(matNameList[index]);
    setMatType(matTypeList[index]);
    setMatMolWeight(matMolWeightList[index]);
    setMatBuffer(matBufferList[index]);
    setMatConc(matConcList[index]);
    setCartridge(cartridgeList[index]);
    setOutflow(outflowList[index]);
    setMixingRatio(mixingRatioList[index]);
    setVoltage(voltageList[index]);
    setDuty(dutyList[index]);
  }
  function updateAsCurrentDeviceModel() {
    // updateAsCurrentDeviceType 값에 맞춰서 화면에 update
    let str: string = currentDeviceModel;
    if (str.indexOf("3P") === 0) {
      setFivePHidden(true);
    }
    if (str.indexOf("5P") === 0) {
      setFivePHidden(false);
    }
  }
  function onCartridgeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCartridge(str);
  }
  function onCellNameTextBoxValueChanged(e: string) {
    setCellName(e);
  }
  function onCellTypeTextBoxValueChanged(e: string) {
    setCellType(e);
  }
  function onCellSizeTextBoxValueChanged(e: string) {
    setCellSize(e);
  }
  function onCellConcTextBoxValueChanged(e: string) {
    setCellConc(e);
  }
  function onCellBufferTextBoxValueChanged(e: string) {
    setCellBuffer(e);
  }
  function onMatNameTextBoxValueChanged(e: string) {
    setMatName(e);
  }
  function onMatTypeTextBoxValueChanged(e: string) {
    setMatType(e);
  }
  function onMatMolWeightTextBoxValueChanged(e: string) {
    setMatMolWeight(e);
  }
  function onMatConcTextBoxValueChanged(e: string) {
    setMatConc(e);
  }
  function onMatBufferTextBoxValueChanged(e: string) {
    setMatBuffer(e);
  }
  function onDeviceSerialTextBoxValueChanged(e: string) {
    setDeviceSerial(e);
  }
  function onDeviceAmpTypeTextBoxValueChanged(e: string) {
    setDeviceAmpType(e);
  }
  function onTxUserNameTextBoxValueChanged(e: string) {
    setTxUserName(e);
  }
  function onMixingRatioTextBoxValueChanged(e: string) {
    setMixingRatio(e);
  }
  function onOutflowTextBoxValueChanged(e: string) {
    setOutflow(e);
  }
  function onHarvestVolumeTextBoxValueChanged(e: string) {
    setHarvestVolume(e);
  }
  function onVoltageTextBoxValueChanged(e: string) {
    setVoltage(e);
  }
  function onDutyTextBoxValueChanged(e: string) {
    setDuty(e);
  }
  function onCurrentTextBoxValueChanged(e: string) {
    setCurrent(e);
  }
  function onCellShotStabilityTextBoxValueChanged(e: string) {
    setCellShotStability(e);
  }
  function onRunningTimeTextBoxValueChanged(e: string) {
    setRunningTime(e);
  }
  function onErrorCaseCheckBoxValueChanged(e: boolean) {
    setErrorCase(e);
  }
  function onRemarkTextBoxValueChanged(e: string) {
    setRemark(e);
  }

  async function onReadButtonClicked() {
    if (!U.isProperTxId(txId)) {
      alert("Enter proper value to TX ID!", "Error");
      return;
    }

    let jsonData: Object = await readJson("/tx/readsample", txId);
    let _txId: string = U.stringValueFromJson(jsonData, "txId");
    if (_txId.length == 0) return;
    // update to display
    setTxId(_txId);
    setTxTime(U.dateValueFromJson(jsonData, "txTime"));
    currentDeviceModel = U.stringValueFromJson(jsonData, "deviceModel");
    setDeviceModel(currentDeviceModel);
    updateAsCurrentDeviceModel();
    setDeviceSerial(U.stringValueFromJson(jsonData, "deviceSerial"));
    setDeviceAmpType(U.stringValueFromJson(jsonData, "deviceAmpType"));

    setProject(U.stringValueFromJson(jsonData, "project"));
    setCellName(U.stringValueFromJson(jsonData, "cellName"));
    setCellType(U.stringValueFromJson(jsonData, "cellType"));
    setCellSize(U.stringValueFromJson(jsonData, "cellSize"));
    setCellBuffer(U.stringValueFromJson(jsonData, "cellBuffer"));
    setCellConc(U.stringValueFromJson(jsonData, "cellConc"));
    setMatName(U.stringValueFromJson(jsonData, "matName"));
    setMatType(U.stringValueFromJson(jsonData, "matType"));
    setMatMolWeight(U.stringValueFromJson(jsonData, "matMolWeight"));
    setMatBuffer(U.stringValueFromJson(jsonData, "matBuffer"));
    setMatConc(U.stringValueFromJson(jsonData, "matConc"));

    setCartridge(U.stringValueFromJson(jsonData, "cartridge"));
    setOutflow(U.stringValueFromJson(jsonData, "outflow"));
    setMixingRatio(U.stringValueFromJson(jsonData, "mixingRatio"));
    setVoltage(U.stringValueFromJson(jsonData, "voltage"));
    setDuty(U.stringValueFromJson(jsonData, "duty"));

    setTxUserName(U.stringValueFromJson(jsonData, "txUserName"));
    setHarvestVolume(U.stringValueFromJson(jsonData, "harvestVolume"));
    setCurrent(U.stringValueFromJson(jsonData, "current"));
    setCellShotStability(U.stringValueFromJson(jsonData, "cellShotStability"));
    setRunningTime(U.stringValueFromJson(jsonData, "runningTime"));
    let errorCaseStr: string = U.stringValueFromJson(jsonData, "errorCase");
    if (errorCaseStr == "true") setErrorCase(true);
    else setErrorCase(false);
    setRemark(U.stringValueFromJson(jsonData, "remark"));
  }

  async function onUploadButtonClicked() {
    if (!U.isProperTxId(txId)) {
      alert("Enter proper value to TX ID!", "Error");
      return;
    }

    if (project.length === 0) {
      alert("Select project!", "Error");
      return;
    }

    // txId에서 txDate를 잘라내서 업로드
    let txDateStr = txId.substring(0, 8);
    let txDateN: number = parseInt(txDateStr);

    // boolean인 column 들에 대해 string에서 int으로 변환 후 upload
    let errorCaseN: number = 0;
    if (errorCase) errorCaseN = 1;

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let cellSizeF: number = U.floatFromString(cellSize, 0);
    let cellConcF: number = U.floatFromString(cellConc, 0);
    let matMolWeightF: number = U.floatFromString(matMolWeight, 0);
    let matConcF: number = U.floatFromString(matConc, 0);
    let outflowF: number = U.floatFromString(outflow, 0);
    let mixingRatioF: number = U.floatFromString(mixingRatio, 0);
    let voltageF: number = U.floatFromString(voltage, 0);
    let dutyF: number = U.floatFromString(duty, 0);
    let harvestVolumeF: number = U.floatFromString(harvestVolume, 0);
    let currentF: number = U.floatFromString(current, 0);
    let runningTimeF: number = U.floatFromString(runningTime, 0);

    let obj: Object = {
      txId: txId,
      txTime: txTime,
      txDate: txDateN,
      deviceModel: deviceModel,
      deviceSerial: deviceSerial,
      deviceAmpType: deviceAmpType,
      project: project,
      cellName: cellName,
      cellType: cellType,
      cellSize: cellSizeF,
      cellBuffer: cellBuffer,
      cellConc: cellConcF,
      matName: matName,
      matType: matType,
      matMolWeight: matMolWeightF,
      matBuffer: matBuffer,
      matConc: matConcF,
      cartridge: cartridge,
      outflow: outflowF,
      mixingRatio: mixingRatioF,
      voltage: voltageF,
      duty: dutyF,

      txUserName: txUserName,
      harvestVolume: harvestVolumeF,
      current: currentF,
      cellShotStability: cellShotStability,

      runningTime: runningTimeF,
      errorCase: errorCaseN,
      remark: remark,
    };

    await uploadJson("/tx/uploadsamplewithid", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Do you want to overwrite?", "Same id exists");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/tx/overwritesample", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>New TX Sample</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="TX ID"
                placeholder="YYYYMMDD-SERIAL-#"
                value={txId}
                valueChangeEvent="keyup"
                onValueChange={onTxIdTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <Button text="Read" onClick={onReadButtonClicked} width={145} />
              </div>

              <div className={"flex-item4"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={145} type="success" icon="upload" />
              </div>
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <DateBox
                label="TX Time"
                type="datetime"
                value={txTime}
                valueChangeEvent="change"
                onValueChange={onTxTimeDateBoxValueChanged}
                width={300}
                height={40}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="Device Model"
                dataSource={["3P TUI", "5P"]}
                value={deviceModel}
                width={300}
                onSelectionChanged={onDeviceModelSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox label="Project" dataSource={projectList} value={project} width={300} onSelectionChanged={onProjectSelectionChanged} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Name" value={cellName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Type" value={cellType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Size (um)" value={cellSize} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Buffer" value={cellBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cell Conc. (10^6 cells/mL)" value={cellConc} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Name" value={matName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Type" value={matType} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Mol. Weight (kDa)" value={matMolWeight} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Buffer" value={matBuffer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mat Conc. (ug/mL)" value={matConc} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cartridge" value={cartridge} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Outflow (mL/h)" value={outflow} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Mixing Ratio (Mat/Cell)" value={mixingRatio} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Voltage (V)" value={voltage} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Duty (%)" value={duty} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Device Serial"
                value={deviceSerial}
                valueChangeEvent="keyup"
                onValueChange={onDeviceSerialTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Device Amp Type"
                value={deviceAmpType}
                valueChangeEvent="keyup"
                onValueChange={onDeviceAmpTypeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="TX User Name"
                value={txUserName}
                valueChangeEvent="keyup"
                onValueChange={onTxUserNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Harvest Volume (mL)"
                value={harvestVolume}
                valueChangeEvent="keyup"
                onValueChange={onHarvestVolumeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Current (mA)"
                value={current}
                valueChangeEvent="keyup"
                onValueChange={onCurrentTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="CellShot Stability"
                value={cellShotStability}
                valueChangeEvent="keyup"
                onValueChange={onCellShotStabilityTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="RunningTime (min)"
                value={runningTime}
                valueChangeEvent="keyup"
                onValueChange={onRunningTimeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="Error Case" value={errorCase} onValueChange={onErrorCaseCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Remark" value={remark} valueChangeEvent="keyup" onValueChange={onRemarkTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

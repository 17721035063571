export const isProperEightDigitStringDate = (text: string) => {
  // YYYYMMDD 형식에 맞는지 체크
  if (text.length !== 8) return false;
  let yearStr = text.substring(0, 4);
  let monthStr = text.substring(4, 6);
  let dayStr = text.substring(6, 8);
  let year = parseInt(yearStr);
  let month = parseInt(monthStr);
  let day = parseInt(dayStr);
  if (Number.isNaN(year)) return false;
  if (Number.isNaN(month)) return false;
  if (Number.isNaN(day)) return false;
  if (year < 1000 || year > 5000) return false;
  if (month < 1 || month > 12) return false;
  if (day < 1 || day > 31) return false;
  return true;
};

export const isProperEightDigitIntDate = (eightDigitInt: number) => {
  return isProperEightDigitStringDate(eightDigitInt.toString());
};

export const isProperFourDigitStringYear = (text: string) => {
  if (text.length !== 4) return false;
  let yearStr = text.substring(0, 4);
  let year = parseInt(yearStr);
  if (Number.isNaN(year)) return false;
  if (year < 1000 || year > 5000) return false;
  return true;
};

export const isProperStringMonth = (text: string) => {
  let month = parseInt(text);
  if (Number.isNaN(month)) return false;
  let strMonth = month.toString();
  if (text != strMonth) return false;
  if (month < 1 || month > 12) return false;
  return true;
};

export const stringDateFromEightDigitIntDate = (eightDigit: number) => {
  // 20230511 숫자를 입력받아 "5/11/2023" 문자열을 반환한다. 0이나 범위에 맞지 않는 값을 입력받으면 "-"을 반환한다.
  let str: string = `${eightDigit}`;
  if (!isProperEightDigitStringDate(str)) return "-";
  let result = intArrayFromEightDigitIntDate(eightDigit);
  let stringDate: string = `${result[1]}/${result[2]}/${result[0]}`;
  return stringDate;
};

export const eightDigitIntDateFromString = (text: string) => {
  // "2022/01/01" or "22/01/01" 등의 문자열을 입력받아 8자리 YYYYMMDD 숫자를 반환한다.
  // 인식이 안되는 문자열이면 0을 반환한다.
  let splitted = text.split("/");
  if (splitted.length === 3) {
    // 2022/01/01 or 22/01/01
    let yearStr = splitted[0];
    let monthStr = splitted[1];
    let dayStr = splitted[2];
    let year: number = 0;
    let month: number = 0;
    let day: number = 0;
    year = parseInt(yearStr);
    month = parseInt(monthStr);
    day = parseInt(dayStr);
    if (year === 0) return 0;
    if (year < 100) year = year + 2000;
    if (year < 1000 || year > 5000) return 0;
    if (month < 1 || 12 < month) return 0;
    if (day < 1 || 31 < day) return 0;
    let eightDigit = year * 10000 + month * 100 + day;
    return eightDigit;
  }
  splitted = text.split(".");
  if (splitted.length === 3) {
    // 2022.01.01 or 22.01.01
    let yearStr = splitted[0];
    let monthStr = splitted[1];
    let dayStr = splitted[2];
    let year: number = 0;
    let month: number = 0;
    let day: number = 0;
    year = parseInt(yearStr);
    month = parseInt(monthStr);
    day = parseInt(dayStr);
    if (year < 100) year = year + 2000;
    if (year === 0) return 0;
    if (month < 1 || 12 < month) return 0;
    if (day < 1 || 31 < day) return 0;
    let eightDigit = year * 10000 + month * 100 + day;
    return eightDigit;
  }
  return 0;
};

export const eightDigitIntDateFromIntArray = (year: number, month: number, day: number) => {
  let eightDigitInt: number = year * 10000 + month * 100 + day;
  return eightDigitInt;
};

export const eightDigitStringDateFromIntArray = (year: number, month: number, day: number) => {
  let eightDigitInt: number = year * 10000 + month * 100 + day;
  let eightDigitString: string = `${eightDigitInt}`;
  return eightDigitString;
};

export const eightDigitIntDateFromDate = (date: Date) => {
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  let eightDigitInt: number = year * 10000 + month * 100 + day;
  return eightDigitInt;
};

export const eightDigitStringDateFromDate = (date: Date) => {
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  let eightDigitInt: number = year * 10000 + month * 100 + day;
  let eightDigitString: string = `${eightDigitInt}`;
  return eightDigitString;
};

export const eightDigitDotStringDateFromDate = (date: Date) => {
  // 2024.01.31
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  let strYear: string = year.toString();
  let strMonth: string = month.toString();
  if (strMonth.length == 1) strMonth = "0" + strMonth;
  let strDay = day.toString();
  if (strDay.length == 1) strDay = "0" + strDay;
  let strDate: string = `${strYear}.${strMonth}.${strDay}`;
  return strDate;
};

export const startLocalDateFromEigitDigitStringDate = (eigitDigitString: string) => {
  // 20230428을 입력하면 local time으로 00:00:00 시간인 Date를 생성해서 반환한다.
  let yearStr: string = eigitDigitString.substring(0, 4);
  let monthStr: string = eigitDigitString.substring(4, 6);
  let dayStr: string = eigitDigitString.substring(6, 8);
  let year: number = parseInt(yearStr);
  let month: number = parseInt(monthStr);
  let day: number = parseInt(dayStr);
  return startLocalDateFromIntArray(year, month, day);
};

export const startLocalDateFromEigitDigitIntDate = (eightDigitInt: number) => {
  // 20230428을 입력하면 local time으로 00:00:00 시간인 Date를 생성해서 반환한다. 0을 포함한 잘못된 값을 입력하지 않도록 주의한다.
  let result = intArrayFromEightDigitIntDate(eightDigitInt);
  return startLocalDateFromIntArray(result[0], result[1], result[2]);
};

export const endLocalDateFromEigitDigitStringDate = (eigitDigitString: string) => {
  // 20230428을 입력하면 local time으로 00:00:00 시간인 Date를 생성해서 반환한다.
  let yearStr: string = eigitDigitString.substring(0, 4);
  let monthStr: string = eigitDigitString.substring(4, 6);
  let dayStr: string = eigitDigitString.substring(6, 8);
  let year: number = parseInt(yearStr);
  let month: number = parseInt(monthStr);
  let day: number = parseInt(dayStr);
  return endLocalDateFromIntArray(year, month, day);
};

export const intArrayFromEightDigitIntDate = (date: number) => {
  // 20230210 으로부터 [2023, 2, 10]을 반환
  let day: number = date % 100;
  date = date - day;
  date = date / 100;
  let month: number = date % 100;
  date = date - month;
  date = date / 100;
  let year = date;
  return [year, month, day];
};

export const fourDigitClockStringFromDate = (localTime: Date) => {
  // 00:00 형태의 string으로 반환한다.
  let h: number = localTime.getHours();
  let m: number = localTime.getMinutes();
  let hh = h.toString();
  if (hh.length < 2) hh = `0${hh}`;
  let mm = m.toString();
  if (mm.length < 2) mm = `0${mm}`;
  let clockString: string = `${hh}:${mm}`;
  return clockString;
};

export const todayEightDigitStringDate = () => {
  let eightDigitInt = todayEightDigitIntDate();
  let eightDigitStr: string = `${eightDigitInt}`;
  return eightDigitStr;
};

export const todayEightDigitIntDate = () => {
  let date = new Date(Date.now());
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  let eightDigitInt: number = year * 10000 + month * 100 + day;
  return eightDigitInt;
};

export const todayFourDigitIntYear = () => {
  let date = new Date(Date.now());
  let year: number = date.getFullYear();
  return year;
};

export const todayIntArray = () => {
  let date = new Date(Date.now());
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  return [year, month, day];
};

export const intArrayFromDate = (date: Date) => {
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
  let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
  return [year, month, day];
};

const maxDay = (y: number, m: number) => {
  // y가 4로 나눠지면 윤년이고 1년이 366일이고 2월은 29일까지이다. 윤년이 아니면 365일에 2월은 28일까지이다.
  if (m == 1) return 31;
  if (m == 2) {
    if (y % 4 == 0) return 29;
    else return 28;
  }
  if (m == 3) return 31;
  if (m == 4) return 30;
  if (m == 5) return 31;
  if (m == 6) return 30;
  if (m == 7) return 31;
  if (m == 8) return 31;
  if (m == 9) return 30;
  if (m == 10) return 31;
  if (m == 11) return 30;
  if (m == 12) return 31;
  return 31;
};

export const findMovedDate = (refY: number, refM: number, refD: number, deltaY: number, deltaM: number, deltaD: number) => {
  // moveD = 10 이면 (y, m, d) 에서 10일 후의 날짜를 (movedY, movedM, movedD)에 기록한다. 음수면 이전의 날짜이다.
  let movedY: number, movedM: number, movedD: number;
  movedY = refY + deltaY;
  movedM = refM + deltaM;
  movedD = refD + deltaD;
  while (movedM < 1) {
    movedY = movedY - 1;
    movedM = movedM + 12;
  }
  while (12 < movedM) {
    movedY = movedY + 1;
    movedM = movedM - 12;
  }

  while (movedD < 1) {
    // 이전날짜로 이동해야 하는 경우
    movedM = movedM - 1;
    if (movedM < 1) {
      movedY = movedY - 1;
      movedM = movedM + 12;
    }
    movedD = movedD + maxDay(movedY, movedM);
  }

  while (maxDay(movedY, movedM) < movedD) {
    // 이후 날짜로 이동해야 하는 경우
    movedD = movedD - maxDay(movedY, movedM);
    movedM = movedM + 1;
    if (12 < movedM) {
      movedY = movedY + 1;
      movedM = movedM - 12;
    }
  }

  return [movedY, movedM, movedD];
};

export const todayStartUTCStringDate = () => {
  let date = new Date(Date.now()); // 현지의 현재시간 표시: Tue Apr 18 2023 18:48:41 GMT+0900 (한국 표준시)
  return startUTCStringDate(date);
};

export const todayEndUTCStringDate = () => {
  let date = new Date(Date.now()); // 현지의 현재시간 표시: Tue Apr 18 2023 18:48:41 GMT+0900 (한국 표준시)
  return endUTCStringDate(date);
};

export const startUTCStringDate = (date: Date) => {
  // 입력받은 date 날짜의 local time 00:00:00 인 시간을 생성한 후 이를 query에 사용할 수 있게 ISO 형태의 UTC time을 반환한다.
  // query로 직접 시간을 비교하려면 서버에는 UTC 기준이며 '2023-04-16 23:16:35' 형태 또는 ISO 형태의 string을 사용해야 한다.
  let date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate()); // 해당 날짜의 local time 00:00:00 인 시간 생성
  let stringDate = date2.toISOString(); // 2023-04-18T15:00:00.000Z
  return stringDate;
};

export const endUTCStringDate = (date: Date) => {
  // 입력받은 date 날짜의 local time 23:59:59 인 시간을 생성한 후 이를 query에 사용할 수 있게 ISO 형태의 UTC time을 반환한다.
  // query로 직접 시간을 비교하려면 서버에는 UTC 기준이며 '2023-04-16 23:16:35' 형태 또는 ISO 형태의 string을 사용해야 한다.
  let date2 = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  let stringDate = date2.toISOString(); // 2023-04-18T15:00:00.000Z
  return stringDate;
};

export const startLocalDateFromIntArray = (year: number, month: number, day: number) => {
  // 입력받은 year, month, day 날짜의 local time 00:00:00 인 시간을 생성한 후 반환한다.
  let date = new Date(year, month - 1, day); // 해당 날짜의 local time 00:00:00 인 시간 생성
  return date;
};

export const endLocalDateFromIntArray = (year: number, month: number, day: number) => {
  // 입력받은 year, month, day 날짜의 local time 00:00:00 인 시간을 생성한 후 반환한다.
  let date = new Date(year, month - 1, day, 23, 59, 59); // 해당 날짜의 local time 00:00:00 인 시간 생성
  return date;
};

export const dayString = (date: Date) => {
  // .getDay()의 값은 0 ~ 6의 정수이며 0: 일, 1: 월, ..., 6: 토
  let intDay = date.getDay();
  if (intDay == 0) return "일";
  if (intDay == 1) return "월";
  if (intDay == 2) return "화";
  if (intDay == 3) return "수";
  if (intDay == 4) return "목";
  if (intDay == 5) return "금";
  if (intDay == 6) return "토";
  return "";
};

import "./coaCartridge.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import {
  queryJsonArray,
  searchCartridgeCoa,
  downloadAndOpenPdf,
  uploadCartridgeCoaWithFile,
  deleteCartridgeCoaByCatNoAndLotNo,
  deleteAndUploadCartridgeCoaByCatNoAndLotNo,
} from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function CoaCartridge() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [catNo, setCatNo] = useState("");
  const [lotNo, setLotNo] = useState("");
  const [catNo2, setCatNo2] = useState("");
  const [lotNo2, setLotNo2] = useState("");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(true);
  const [hiddenFile, setHiddenFile] = useState<boolean>(true);
  const [file, setFile] = useState<File>();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedCatNo, setSelectedCatNo] = useState("");
  const [selectedLotNo, setSelectedLotNo] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_tx = 0;
    if (user) {
      setUserId(user.id);
      acc_tx = user.acc_tx;
    }
    // (tx) [7] Cartridge CoA  W, [8] D
    if (U.bitAt(acc_tx, 7)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_tx, 8)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      searchGrid();
    }
  }, []);

  async function searchGrid() {
    setSelectedCatNo("");
    setSelectedLotNo("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM coacartridge";
    query += " ORDER BY catNo Asc, lotNo Asc";
    queryJsonArray("/coa/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onSearchButtonClicked() {
    search(catNo, lotNo);
  }

  async function search(catNoToSearch: string, lotNoToSearch: string) {
    if (!catNoToSearch || !lotNoToSearch) {
      alert("Fill in inputs!", "Error");
      return;
    }

    let returned = await searchCartridgeCoa(catNoToSearch, lotNoToSearch);
    // return [_response_status, _no];
    if (!returned) return;
    let no = returned[1];
    if (returned[0] === 0) {
      let fileName = `${catNoToSearch}${lotNoToSearch}.pdf`;
      downloadAndOpenPdf("/coa/downloadcartridgecoafile", fileName);
    } else {
      alert(`${catNoToSearch}-${lotNoToSearch} file does not exists!`, "Error");
    }
  }

  function onCatNoTextBoxValueChanged(e: string) {
    setCatNo(e);
  }

  function onLotNoTextBoxValueChanged(e: string) {
    setLotNo(e);
  }

  function onCatNo2TextBoxValueChanged(e: string) {
    setCatNo2(e);
  }

  function onLotNo2TextBoxValueChanged(e: string) {
    setLotNo2(e);
  }

  function onDeleteButtonClicked() {
    if (!catNo || !lotNo) {
      alert("Fill in inputs!", "Error");
      return;
    }

    let res = confirm(`${catNo}-${lotNo}`, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteCartridgeCoaByCatNoAndLotNo(catNo, lotNo);
      } else {
      }
    });
  }

  function onNewButtonClicked() {
    setHiddenFile(!hiddenFile);
  }

  async function onUploadButtonClicked() {
    if (!catNo2 || !lotNo2) {
      alert("Fill in inputs!", "Error");
      return;
    }

    if (!file) {
      alert("Input file first!", "Error");
      return;
    }

    let returned = await uploadCartridgeCoaWithFile(catNo2, lotNo2, file);
    // return [_response_status, _no];
    if (!returned) return;
    if (returned[0] === 22) {
      let res = confirm(`${catNo}-${lotNo} file already exists!\r\nDo you want to overwrite?`, "Confirm");
      res.then((dialogResult) => {
        if (dialogResult) {
          // Yes
          deleteAndUploadCartridgeCoaByCatNoAndLotNo(catNo2, lotNo2, file);
        }
      });
    }
  }

  const onChangePdf = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onDownloadButtonClicked() {
    if (selectedCatNo.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    setCatNo(selectedCatNo);
    setLotNo(selectedLotNo);
    search(selectedCatNo, selectedLotNo);
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedCatNo(e.selectedRowsData[0].catNo);
      setSelectedLotNo(e.selectedRowsData[0].lotNo);
    }
  }

  function onDataGridCellDbClick(e: any) {
    let selectedCN: string = U.stringValueFromJson(e.row.data, "catNo");
    let selectedLN: string = U.stringValueFromJson(e.row.data, "lotNo");
    if (selectedCN.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    setCatNo(selectedCN);
    setLotNo(selectedLN);
    search(selectedCN, selectedLN);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cartridge CoA</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Cat. No." value={catNo} valueChangeEvent="keyup" onValueChange={onCatNoTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Lot No." value={lotNo} valueChangeEvent="keyup" onValueChange={onLotNoTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={hiddenFile}>
              <TextBox
                label="Cat. No."
                value={catNo2}
                valueChangeEvent="keyup"
                onValueChange={onCatNo2TextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={hiddenFile}>
              <TextBox label="Lot No." value={lotNo2} valueChangeEvent="keyup" onValueChange={onLotNo2TextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={hiddenFile}>
              <p>Cartridge CoA (pdf)</p>
            </div>
            <div className={"flex-item1"} hidden={hiddenFile}>
              <input type="file" accept=".pdf" onChange={onChangePdf} />
            </div>
          </div>

          <div className={"flex-item3"} hidden={hiddenFile}>
            <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
          </div>

          <div className={"flex-item3"}>
            <Button text="Download" onClick={onDownloadButtonClicked} type="default" icon="download" />
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGridCellDbClick}
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="catNo" caption="Cat. No." />
              <Column dataField="lotNo" caption="Lot No." />
              <Column dataField="updated_by" caption="Upload User" dataType="datetime" />
              <Column dataField="updated_at" caption="Upload Date" dataType="datetime" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Summary>
                <TotalItem column="catNo" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

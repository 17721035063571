import {
  HomePage,
  DashboardPage,
  ProfilePage,
  DevicePage,
  CoaDeviceDataPage,
  CoaDevicePage,
  CoaCartridgePage,
  DeviceNewPage,
  CellPage,
  CellNewPage,
  MatPage,
  MatNewPage,
  ProtocolPage,
  ProtocolNewPage,
  ProjectPage,
  ProjectNewPage,
  TxSamplePage,
  TxSampleNewPage,
  TxReportPage,
  FacsPage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/dashboard",
    element: DashboardPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },
  // Device Monitoring
  {
    path: "/device",
    element: DevicePage,
  },
  {
    path: "/device/new",
    element: DeviceNewPage,
  },
  {
    path: "/coa/device/data",
    element: CoaDeviceDataPage,
  },
  {
    path: "/coa/device",
    element: CoaDevicePage,
  },
  {
    path: "/coa/cartridge",
    element: CoaCartridgePage,
  },
  // Project Management
  {
    path: "/cell",
    element: CellPage,
  },
  {
    path: "/cell/new",
    element: CellNewPage,
  },
  {
    path: "/mat",
    element: MatPage,
  },
  {
    path: "/mat/new",
    element: MatNewPage,
  },
  {
    path: "/protocol",
    element: ProtocolPage,
  },
  {
    path: "/protocol/new",
    element: ProtocolNewPage,
  },
  {
    path: "/project",
    element: ProjectPage,
  },
  {
    path: "/project/new",
    element: ProjectNewPage,
  },
  // Data Analysis
  {
    path: "/txSample",
    element: TxSamplePage,
  },
  {
    path: "/txSample/new",
    element: TxSampleNewPage,
  },
  {
    path: "/txReport",
    element: TxReportPage,
  },
  {
    path: "/facs",
    element: FacsPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});

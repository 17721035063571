import "./facs.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import Gallery from "devextreme-react/gallery";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import { queryJsonArray, downloadFacsImages } from "../../api/auth";
import * as U from "../../utils";
import { useNavigate } from "react-router-dom";

export default function Shareholder() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [gallery, setGallery] = useState<string[]>();
  const [isGalleryHidden, setIsGalleryHidden] = useState(true);
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedFacsId, setSelectedFacsId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_facs = 0;
    if (user) {
      setUserId(user.id);
      acc_facs = user.acc_facs;
    }
    // (facs) [0] FACS R & W, [1] D, [2] Gating R & W & E & D
    if (U.bitAt(acc_facs, 1)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    let array = [
      "https://js.devexpress.com/Content/images/doc/22_2/PhoneJS/person1.png",
      "https://js.devexpress.com/Content/images/doc/22_2/PhoneJS/person2.png",
    ];
    setGallery(array);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      let dateRange = calculateDateRange("1 month");
      if (dateRange) search(dateRange[0], dateRange[1]);
    }
  }, []);

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }

  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }

  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1 week") moveD = -6;
    if (str === "1 month") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2 months") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6 months") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1 year") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2 years") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  function onSearchButtonClicked() {
    search(filterFrom, filterTo);
  }

  function search(from: string, to: string) {
    setIsGalleryHidden(true);
    setSelectedFacsId("");
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM facs";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += ` AND facsDate >= ${dateFrom}`;
    query += ` AND facsDate <= ${dateTo}`;
    query += " ORDER BY facsDate Desc, facsId Asc";
    queryJsonArray("/tx/queryfacs", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  async function onFacsImageButtonClicked() {
    if (selectedFacsId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let urlArray: Array<string> = await downloadFacsImages(selectedFacsId);
    if (urlArray.length !== 0) {
      setGallery(urlArray);
      setIsGalleryHidden(false);
    } else {
      alert("No images in server!", "Error");
      setIsGalleryHidden(true);
    }
  }

  function onFacsAnalyzerButtonClicked() {
    navigate("/home");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedFacsId(e.selectedRowsData[0].facsId);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>FACS Result</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1 week", "1 month", "2 months", "6 months", "1 year", "2 years"]}
                defaultValue={"1 month"}
                width={120}
                height={40}
                onSelectionChanged={onDateSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="FACS Image" onClick={onFacsImageButtonClicked} />
            </div>
          </div>

          <div className="widget-container" hidden={isGalleryHidden}>
            <Gallery id="gallery" dataSource={gallery} height="100%" width="100%" loop={true} showNavButtons={true} showIndicator={true} />
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="facsId" caption="FACS ID" />
              <Column dataField="txId" caption="TX ID" />
              <Column dataField="gatingTrack" caption="Gating Track" />
              <Column dataField="gatingStrategy" caption="Gating Strategy" />
              <Column dataField="project" caption="Project" />
              <Column dataField="deviceModel" caption="Device Model" />
              <Column dataField="p1" caption="P1 (%)" dataType="number" format="#,##0.#" />
              <Column dataField="viability" caption="Viability (%)" dataType="number" format="#,##0.#" />
              <Column dataField="efficiency" caption="Efficiency (%)" dataType="number" format="#,##0.#" />
              <Column dataField="mfi" caption="MFI" dataType="number" format="#,##0.#" />
              <Column dataField="mfiFold" caption="MFI Fold" dataType="number" format="#,##0.##" />
              <Column dataField="controlP1" caption="Control P1 (%)" dataType="number" format="#,##0.#" />
              <Column dataField="controlViability" caption="Control Viability (%)" dataType="number" format="#,##0.#" />
              <Column dataField="controlMfi" caption="Control MFI" dataType="number" format="#,##0.#" />
              <Column dataField="cultureHours" caption="Culture Hours" dataType="number" format="#,##0.#" />
              <Column dataField="cellName" caption="Cell Name" />
              <Column dataField="cellType" caption="Cell Type" />
              <Column dataField="cellSize" caption="Cell Size (um)" dataType="number" format="#,##0.#" />
              <Column dataField="cellConc" caption="Cell Conc. (10^6 cells/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="cellBuffer" caption="Cell Buffer" />
              <Column dataField="matName" caption="Mat Name" />
              <Column dataField="matType" caption="Mat Type" />
              <Column dataField="matMolWeight" caption="Mat Mol. Weight (kDa)" dataType="number" format="#,##0.#" />
              <Column dataField="matConc" caption="Mat Conc. (ug/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="matBuffer" caption="Mat Buffer" />
              <Column dataField="txUserId" caption="TX User ID" />
              <Column dataField="cartridge" caption="Cartridge" />
              <Column dataField="outflow" caption="Outflow (mL/h)" dataType="number" format="#,##0.#" />
              <Column dataField="mixingRatio" caption="Mixing Ratio (M/C)" dataType="number" format="#,##0.#" />
              <Column dataField="voltage" caption="Voltage (V)" dataType="number" format="#,##0.#" />
              <Column dataField="duty" caption="Duty (%)" dataType="number" format="#,##0.#" />
              <Column dataField="harvestVolume" caption="Harvest Volume (mL)" dataType="number" format="#,##0.#" />
              <Column dataField="current" caption="Current (mA)" dataType="number" format="#,##0.#" />
              <Column dataField="cellShotStability" caption="CellShot Stability" />
              <Column dataField="runningTime" caption="Running Time (min)" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="facsTime" caption="FACS Time" dataType="datetime" />
              <Column dataField="txTime" caption="TX Time" dataType="datetime" />

              <Column dataField="remark" caption="Remark" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="facsId" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="FACS Analyzer" onClick={onFacsAnalyzerButtonClicked} icon="download" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

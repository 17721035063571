import { createContext, useContext } from "react";
// cartridge는 사용자가 변경할 수 없으므로 로그인할 때 한 번 다운로드 받아서 계속 사용한다.
// cell, material, protocol, project는 변경사항을 바로 반영하도록 사용할 때마다 새로 다운로드 받는다.
export type CartridgeContextType = {
  codeList: Array<string>;
  outflowList: Array<number>;
};
const CartridgeContext = createContext<CartridgeContextType>(
  {} as CartridgeContextType
);
const useCartridge = () => useContext(CartridgeContext);
export { useCartridge };

export default {
  avatarUrl: "",
  affiliation: "",
  id: "",
  level: "",
  acc_admin: 0,
  acc_tx: 0,
  acc_proj: 0,
  acc_facs: 0,
};

import "./matNew.scss";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { alert, confirm } from "devextreme/ui/dialog";
import { readJson, uploadJson, uploadJsonAndAlert } from "../../api/auth";
import * as U from "../../utils";

export default function MatNew() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [matName, setMatName] = useState("");
  const [matType, setMatType] = useState("");
  const [matMolWeight, setMatMolWeight] = useState("");
  const [matBuffer, setMatBuffer] = useState("");

  useEffect(() => {}, []);

  function onMatNameTextBoxValueChanged(e: string) {
    setMatName(e);
  }
  function onMatTypeTextBoxValueChanged(e: string) {
    setMatType(e);
  }
  function onMatMolWeightTextBoxValueChanged(e: string) {
    setMatMolWeight(e);
  }
  function onMatBufferTextBoxValueChanged(e: string) {
    setMatBuffer(e);
  }

  async function onUploadButtonClicked() {
    if (matName.length === 0) {
      alert("Enter mat name!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let matMolWeightF: number = parseFloat(matMolWeight);
    if (Number.isNaN(matMolWeightF)) matMolWeightF = 0;

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let matName2: string = U.replaceBrace(matName);
    let matType2: string = U.replaceBrace(matType);
    let matBuffer2: string = U.replaceBrace(matBuffer);

    let obj: Object = {
      name: matName2,
      type: matType2,
      molWeight: matMolWeightF,
      buffer: matBuffer2,
    };

    await uploadJson("/project/uploadmat", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        let res = confirm("Do you want to overwrite?", "Same name exists");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/project/overwritemat", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>New Material</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Mat Name"
                value={matName}
                valueChangeEvent="keyup"
                onValueChange={onMatNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mat Type"
                value={matType}
                valueChangeEvent="keyup"
                onValueChange={onMatTypeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mat Mol.Weight (kDa)"
                value={matMolWeight}
                valueChangeEvent="keyup"
                onValueChange={onMatMolWeightTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Mat Buffer"
                value={matBuffer}
                valueChangeEvent="keyup"
                onValueChange={onMatBufferTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="success" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

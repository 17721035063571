import "./profile.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAuth } from "../../contexts/auth";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { alert, confirm } from "devextreme/ui/dialog";
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from "devextreme-react/form";
import { changePasswordAndAlert } from "../../api/auth";

export default function Profile() {
  const { user } = useAuth();

  const [userAffiliation, setUserAffiliation] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [info, setInfo] = useState<Object>();
  const [note, setNote] = useState<string>("");
  const formData = useRef({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setUserAffiliation(user.affiliation);
      setUserId(user.id);
      setAvatarUrl(user.avatarUrl);
      let obj: Object = new Object();
      let str: string = `{"Affiliation":"${user.affiliation}","ID":"${user.id}"}`;
      obj = JSON.parse(str);
      setInfo(obj);
      setNote(`${user.id}`);
    }
  }, []);

  function onChangePasswordButtonClicked() {
    setIsVisible(!isVisible);
  }

  const onSubmit = useCallback(async (e: any) => {
    //e.preventDefault();
    const { password, newPassword, confirmPassword } = formData.current;
    if (newPassword.length === 0) {
      alert("Enter new password!", "Error");
      return;
    }
    if (newPassword !== confirmPassword) {
      alert("Password does not match!", "Error");
      return;
    }
    changePasswordAndAlert(password, newPassword);
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Profile</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        <div className={"form-avatar"}>
          <img alt={""} src={avatarUrl} />
        </div>
        <span>{note}</span>
      </div>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form id={"form"} readOnly={true} formData={info} labelLocation={"top"} colCountByScreen={colCountByScreen} />

        <div className={"flex-containerV"}>
          <Button text="Change Password" onClick={onChangePasswordButtonClicked} icon="unlock" />
        </div>

        <div>
          <Form formData={formData.current} colCountByScreen={colCountByScreen} visible={isVisible}>
            <Item
              dataField={"password"}
              editorType={"dxTextBox"}
              editorOptions={{
                stylingMode: "filled",
                placeholder: "Password",
                mode: "password",
              }}
            >
              <Label visible={true} />
            </Item>
            <Item
              dataField={"newPassword"}
              editorType={"dxTextBox"}
              editorOptions={{
                stylingMode: "filled",
                placeholder: "New Password",
                mode: "password",
              }}
            >
              <Label visible={true} />
            </Item>
            <Item
              dataField={"confirmPassword"}
              editorType={"dxTextBox"}
              editorOptions={{
                stylingMode: "filled",
                placeholder: "Confirm Password",
                mode: "password",
              }}
            >
              <Label visible={true} />
            </Item>
            <ButtonItem>
              <ButtonOptions onClick={onSubmit} width={"100%"} height={83} type={"default"}>
                Upload
              </ButtonOptions>
            </ButtonItem>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};

import "./coaDevice.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import {
  queryJsonArray,
  searchDeviceCoa,
  downloadAndOpenPdf,
  uploadDeviceCoaWithFile,
  deleteDeviceCoaByDeviceSerial,
  deleteAndUploadDeviceCoaByDeviceSerial,
} from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function CoaDevice() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [deviceSerial, setDeviceSerial] = useState("");
  const [deviceSerial2, setDeviceSerial2] = useState("");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(true);
  const [hiddenFile, setHiddenFile] = useState<boolean>(true);
  const [file, setFile] = useState<File>();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedDeviceSerial, setSelectedDeviceSerial] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_tx = 0;
    if (user) {
      setUserId(user.id);
      acc_tx = user.acc_tx;
    }
    // (tx) [5] Device Cal & CoA W, [6] D
    if (U.bitAt(acc_tx, 5)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_tx, 6)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      searchGrid();
    }
  }, []);

  async function searchGrid() {
    setSelectedDeviceSerial("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM coadevice";
    if (userAffiliation !== "femtobiomed" && userAffiliation !== "valid") query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY deviceSerial Asc";
    queryJsonArray("/coa/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onSearchButtonClicked() {
    search(deviceSerial);
  }

  async function search(deviceSerialToSearch: string) {
    if (!deviceSerialToSearch) {
      alert("Fill in device serial!", "Error");
      return;
    }

    let returned = await searchDeviceCoa(deviceSerialToSearch);
    // return [_response_status, _no];
    if (!returned) return;
    let no = returned[1];
    if (returned[0] === 0) {
      let res = confirm(`${deviceSerialToSearch} file exists! Do you want to open it?`, "Confirm");
      res.then((dialogResult) => {
        if (dialogResult) {
          // Yes
          let fileName = `${no}.pdf`;
          downloadAndOpenPdf("/coa/downloaddevicecoafile", fileName);
        }
      });
    } else {
      alert(`${deviceSerialToSearch} file does not exists!`, "Error");
    }
  }

  function onDeviceSerialTextBoxValueChanged(e: string) {
    setDeviceSerial(e);
  }

  function onDeviceSerial2TextBoxValueChanged(e: string) {
    setDeviceSerial2(e);
  }

  function onDeleteButtonClicked() {
    if (!deviceSerial) {
      alert("Fill in device serial!", "Error");
      return;
    }

    let res = confirm(deviceSerial, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteDeviceCoaByDeviceSerial(deviceSerial);
      } else {
      }
    });
  }

  function onNewButtonClicked() {
    setHiddenFile(!hiddenFile);
  }

  async function onUploadButtonClicked() {
    if (!deviceSerial2) {
      alert("Fill in device serial!", "Error");
      return;
    }

    if (!file) {
      alert("Input file first!", "Error");
      return;
    }

    let returned = await uploadDeviceCoaWithFile(deviceSerial2, file);
    // return [_response_status, _no];
    if (!returned) return;
    if (returned[0] === 22) {
      let res = confirm(`${deviceSerial2} file already exists!\r\nDo you want to overwrite?`, "Confirm");
      res.then((dialogResult) => {
        if (dialogResult) {
          // Yes
          deleteAndUploadDeviceCoaByDeviceSerial(deviceSerial2, file);
        }
      });
    }
  }

  const onChangePdf = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onDownloadButtonClicked() {
    if (selectedDeviceSerial.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    setDeviceSerial(selectedDeviceSerial);
    search(selectedDeviceSerial);
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedDeviceSerial(e.selectedRowsData[0].deviceSerial);
    }
  }

  function onDataGridCellDbClick(e: any) {
    let selectedDS: string = U.stringValueFromJson(e.row.data, "deviceSerial");
    if (selectedDS.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    setDeviceSerial(selectedDS);
    search(selectedDS);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Device CoA</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Device Serial"
                value={deviceSerial}
                valueChangeEvent="keyup"
                onValueChange={onDeviceSerialTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"} hidden={hiddenFile}>
              <TextBox
                label="Device Serial"
                value={deviceSerial2}
                valueChangeEvent="keyup"
                onValueChange={onDeviceSerial2TextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={hiddenFile}>
              <p>Device CoA (pdf)</p>
            </div>
            <div className={"flex-item1"} hidden={hiddenFile}>
              <input type="file" accept=".pdf" onChange={onChangePdf} />
            </div>
          </div>

          <div className={"flex-item3"} hidden={hiddenFile}>
            <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
          </div>

          <div className={"flex-item3"}>
            <Button text="Download" onClick={onDownloadButtonClicked} type="default" icon="download" />
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGridCellDbClick}
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="updated_by" caption="Upload User" dataType="datetime" />
              <Column dataField="updated_at" caption="Upload Date" dataType="datetime" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Summary>
                <TotalItem column="deviceSerial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import "./txSample.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCommon } from "../../contexts/common";
// api
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function TxSample() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const commonContext = useCommon();

  const [userId, setUserId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [project, setProject] = useState("");
  const [projectList, setProjectList] = useState<Array<string>>([]);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedTxId, setSelectedTxId] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_tx = 0;
    if (user) {
      setUserId(user.id);
      acc_tx = user.acc_tx;
    }
    // (tx) [0] tx sample R & W, [1] D
    if (U.bitAt(acc_tx, 1)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      initializeList();
      setProject("");
      let dateRange = calculateDateRange("1 month");
      if (dateRange) search(dateRange[0], dateRange[1], "");
    }
  }, []);

  function initializeList() {
    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    // cell, mat, protocol은 같이 연산할 내용은 없으므로 각자 query 및 then 처리를 해준다.
    let query = "SELECT id FROM project";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY id Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let projectL = [""];
      for (let i = 0; i < projectJson.length; i++) {
        projectL.push(U.stringValueFromJson(projectJson[i], "id"));
      }
      setProjectList(projectL);
    });
  }

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }

  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function onProjectSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setProject(str);
  }

  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1 week") moveD = -6;
    if (str === "1 month") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2 months") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6 months") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1 year") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2 years") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  function onSearchButtonClicked() {
    search(filterFrom, filterTo, project);
  }

  function search(from: string, to: string, projectName: string) {
    setSelectedTxId("");
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM sample";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    if (projectName.length > 0) query += ` AND project = '${projectName}'`;
    query += ` AND txDate >= ${dateFrom}`;
    query += ` AND txDate <= ${dateTo}`;
    query += " ORDER BY txTime Desc";
    queryJsonArray("/tx/querysample", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let maxVolume1: number = U.intValueFromJson(oneRowJson, "maxVolume");
        let maxVolume2: string = "Off";
        if (maxVolume1 === 1) maxVolume2 = "On";
        oneRowJson = U.addStringToJson(oneRowJson, "maxVolume2", maxVolume2);
        if (oneRowJson === null) continue;
        // ...
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
      //setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    navigate("/txSample/new");
  }

  function onDeleteButtonClicked() {
    if (selectedTxId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedTxId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/tx/deletesample", selectedTxId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onReportButtonClicked() {
    if (selectedTxId.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    commonContext.str1 = selectedTxId;
    navigate("/txReport");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedTxId(e.selectedRowsData[0].txId);
    }
  }

  function onDataGridCellDbClick(e: any) {
    let txId: string = U.stringValueFromJson(e.row.data, "txId");
    if (txId.length == 0) {
      alert("Select row first!", "Error");
      return;
    }
    commonContext.str1 = txId;
    navigate("/txReport");
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>CellShot Results</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1 week", "1 month", "2 months", "6 months", "1 year", "2 years"]}
                defaultValue={"1 month"}
                width={120}
                height={40}
                onSelectionChanged={onDateSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                placeholder="Project"
                dataSource={projectList}
                value={project}
                width={240}
                height={40}
                onSelectionChanged={onProjectSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Report" onClick={onReportButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGridCellDbClick}
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="txId" caption="Result ID" />
              <Column dataField="txTime" caption="TX Time" dataType="datetime" />
              <Column dataField="project" caption="Project" />
              <Column dataField="protocol" caption="Protocol" />
              <Column dataField="deviceModel" caption="Device Model" />
              <Column dataField="cartridge" caption="Cartridge" />
              <Column dataField="txUserId" caption="TX User ID" />
              <Column dataField="freq" caption="Frequency (kHz)" dataType="number" format="#,##0.#" />
              <Column dataField="voltage" caption="Voltage (V)" dataType="number" format="#,##0.#" />
              <Column dataField="duty" caption="Duty (%)" dataType="number" format="#,##0.#" />
              <Column dataField="outflow" caption="Outflow (mL/h)" dataType="number" format="#,##0.#" />
              <Column dataField="mixingRatio" caption="Mixing Ratio (M/C)" dataType="number" format="#,##0.#" />
              <Column dataField="harvestVolume" caption="Harvest Volume (mL)" dataType="number" format="#,##0.#" />
              <Column dataField="cellName" caption="Cell Name" />
              <Column dataField="cellType" caption="Cell Type" />
              <Column dataField="cellSize" caption="Cell Size (um)" dataType="number" format="#,##0.#" />
              <Column dataField="cellBuffer" caption="Cell Buffer" />
              <Column dataField="cellConc" caption="Cell Conc. (10^6 cells/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="matName" caption="Mat Name" />
              <Column dataField="matType" caption="Mat Type" />
              <Column dataField="matMolWeight" caption="Mat Mol. Weight (kDa)" dataType="number" format="#,##0.#" />
              <Column dataField="matBuffer" caption="Mat Buffer" />
              <Column dataField="matConc" caption="Mat Conc. (ug/mL)" dataType="number" format="#,##0.#" />
              <Column dataField="cooler" caption="Cooler (%)" dataType="number" format="#,##0.#" />
              <Column dataField="maxVolume2" caption="Max Volume" />
              <Column dataField="harvestVolume" caption="Harvest Volume (mL)" dataType="number" format="#,##0.#" />
              <Column dataField="current" caption="Current (mA)" dataType="number" format="#,##0.#" />
              <Column dataField="runningTime" caption="Running Time (min)" />
              <Column dataField="deviceSerial" caption="Device Serial" />
              <Column dataField="deviceAmpType" caption="Device Amp Type" />
              <Column dataField="note" caption="Note" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="txId" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="runningTime" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import { createContext, useContext } from "react";
export type CommonContextType = {
  str1: string;
  str2: string;
  str3: string;
  str4: string;
  num1: number;
  num2: number;
  num3: number;
  num4: number;
  date1: Date;
  date2: Date;
  date3: Date;
  date4: Date;
};
const CommonContext = createContext<CommonContextType>({} as CommonContextType);
const useCommon = () => useContext(CommonContext);
export { useCommon };

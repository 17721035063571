import "./cell.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import { test, queryJsonArray, deleteRowById } from "../../api/auth";
import * as U from "../../utils";
import { useNavigate } from "react-router-dom";

export default function Cell() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedName, setSelectedName] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_proj = 0;
    if (user) {
      setUserId(user.id);
      acc_proj = user.acc_proj;
    }
    // (proj) [0] query for project, cell, mat, protocol, [3] cell, mat, protocol W & E, [4] D
    if (U.bitAt(acc_proj, 3)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_proj, 4)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedName("");

    let userAffiliation: string = "";
    if (user) {
      userAffiliation = user.affiliation;
    }

    let query = "SELECT * FROM cell";
    query += ` WHERE affiliation = '${userAffiliation}'`;
    query += " ORDER BY name Asc";
    queryJsonArray("/project/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    navigate("/cell/new");
  }

  function onDeleteButtonClicked() {
    if (selectedName.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/project/deletecell", selectedName).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedName(e.selectedRowsData[0].name);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cell</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="success" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="name" caption="Cell Name" />
              <Column dataField="type" caption="Cell Type" />
              <Column dataField="size" caption="Cell Size" dataType="number" format="#,##0.#" />
              <Column dataField="buffer" caption="Cell Buffer" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
